import React, {useEffect, lazy, useState} from 'react';
import {Switch, useLocation, useHistory, Redirect} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {translatePathname} from 'lib/url_localization';
import api from 'lib/api';
import {ALERT_LABELS} from 'lib/constants';
import mixpanelWrapper from 'lib/externalAnalytics/mixpanel';
import {trackFromQueryParam} from 'lib/externalAnalytics/eventLogger';

import AuthenticatedRoute from 'components/routing/AuthenticatedRoute';
import LocalizedRedirect from 'components/routing/LocalizedRedirect';
import UnauthenticatedRoute from 'components/routing/UnauthenticatedRoute';
import useAuthStatus from 'hooks/useAuthStatus';

const LogIn = lazy(() => import('pages/login/'));
const Sage = lazy(() => import('pages/sage/Sage'));
const CreatePassword = lazy(() => import('pages/create_password'));
const ForgotPassword = lazy(() => import('pages/forgot_password'));
const SuccessForgotPassword = lazy(() => import('pages/forgot_password/success_forgot_password'));

function AppRouter() {
  const location = useLocation();
  const history = useHistory();

  const {i18n} = useTranslation();
  const {isLoggedIn} = useAuthStatus();
  const [trackPayload, setTrackPayload] = useState(null);

  const {
    language,
    languages: [preferredLanguageFormat],
  } = i18n;

  useEffect(() => {
    const onLogout = () => {
      mixpanelWrapper.resetUserProfile();
    };

    api.setLogoutCallback(onLogout);
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(location?.search);
    const trackParamValue = urlParams.get('track');
    if (trackParamValue) {
      setTrackPayload(trackParamValue);
      urlParams.delete('track');

      history.replace({
        pathname: location.pathname,
        search: urlParams.toString(),
        hash: location.hash,
      });
    }
  }, [location, history]);

  useEffect(() => {
    if (trackPayload) {
      try {
        trackFromQueryParam(trackPayload);
      } catch (e) {
        console.error(`Track failed due to error ${e}`);
        if (isLoggedIn) {
          api.internalAlert({
            label: ALERT_LABELS.LINK_TRACKING_FAILED,
            message: `Track failed due to error ${e}`,
          });
        }
      } finally {
        setTrackPayload(null);
      }
    }
  }, [trackPayload, isLoggedIn]);

  useEffect(() => {
    // We want i18n.language to respect the configured load and supportedLng
    // https://www.i18next.com/overview/api#language
    // If it does not match the preferred format update it
    if (language !== preferredLanguageFormat) {
      i18n.changeLanguage(preferredLanguageFormat);
    }
  }, [i18n, language, preferredLanguageFormat]);

  const localizedPathname = translatePathname(location.pathname, preferredLanguageFormat);
  if (location.pathname !== localizedPathname) {
    return <Redirect push to={localizedPathname + location.search} />;
  }

  return (
    <Switch>
      <UnauthenticatedRoute path={translatePathname('/log_in', language)} component={LogIn} />
      <UnauthenticatedRoute path={translatePathname('/forgot_password', language)} component={ForgotPassword} />
      <UnauthenticatedRoute
        path={translatePathname('/success_forgot_password', language)}
        component={SuccessForgotPassword}
      />
      <UnauthenticatedRoute path={translatePathname('/create_password', language)} component={CreatePassword} />
      <AuthenticatedRoute path={translatePathname('/', language)} component={Sage} />
      <LocalizedRedirect
        push
        to={{
          pathname: '/',
          state: {...location},
        }}
      />
    </Switch>
  );
}

export default AppRouter;
