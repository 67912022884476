import {PAGES} from 'lib/pages';

export const PATH_LANGUAGE_REGEX = '^([a-z]{2}|[a-z]{2}-[A-Z]{2})$';

/**
 * Attempts to translate the given pathname. Will add the language to the path if not present.
 * @param {String} pathname
 * @param {String} language
 * @returns {String}
 */
export function translatePathname(pathname, language) {
  let pathParts = pathname.split('/').filter((path) => path);

  if (pathParts.length > 0 && pathParts[0].match(PATH_LANGUAGE_REGEX)) {
    pathParts[0] = language;
  } else {
    pathParts.unshift(language);
  }

  pathParts = [
    pathParts[0],
    ...pathParts.slice(1).map((pathPart) => {
      const pathObj = PAGES.map((page) => page.url_localization).find((pathTranslations) =>
        Object.values(pathTranslations).includes(pathPart),
      );
      return pathObj ? pathObj[language] : pathPart;
    }),
  ];

  return '/' + pathParts.join('/');
}
