import {UNASSIGNED_SECTION} from 'lib/constants';
import {uniqueId} from 'lodash';
import {useEffect, useRef} from 'react';

/** Get initials for the given name */
export function getInitials(firstName, lastName) {
  const initials = (firstName ? firstName[0] : '') + (lastName ? lastName[0] : '');
  return initials.toUpperCase();
}

/** Create a random password */
export function createPassword() {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  let result = 'Aa1@';
  for (let i = 0; i < 10; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

/**
 * Helper function to perform a locale-aware case-insensitive sort of an array of objects,
 * based on a particular string property of all objects.
 * @param {Object[]} arr      An array of objects to be sorted.
 * @param {String}   sortProp The name of the property of each object to use for comparison.
 *                            These object properties should contain a string value.
 *                            If the property contains a falsy value, an empty string is used for comparison instead.
 * @returns {Object[]} A shallow clone of the array with the entries in sorted order.
 */
export function localeSortByStringProperty(arr, sortProp) {
  return arr.slice().sort((a, b) => {
    const valA = a[sortProp] || '';
    const valB = b[sortProp] || '';
    return valA.localeCompare(valB, {sensitivity: 'base'});
  });
}

export function usePrevious(state) {
  const ref = useRef();

  useEffect(() => {
    ref.current = state;
  });

  return ref.current;
}

/**
 * Used to check if the application is running in a node prod environment
 * @returns The boolean value representing if the app is in the production environment
 */
export function isEnvironmentProduction() {
  return process.env.NODE_ENV === 'production';
}

/**
 * Used to check if the application is running in the prod stage
 * @returns The boolean value representing if the app is in the production stage
 */
export function isStageProduction() {
  return process.env?.REACT_APP_STAGE_NAME === 'production';
}

export function getFavoriteSectionId(favorite) {
  return favorite !== undefined ? favorite?.section?.id || UNASSIGNED_SECTION.id : null;
}

export function generateKey(prefix = '') {
  return uniqueId(prefix);
}

export function sanitizeUrl(url) {
  if (!/^https?:\/\//i.test(url)) {
    url = 'http://' + url;
  }

  return url;
}

/**
 * Helper function to reduce nested objects into flat objects while maintaining all other data types.
 * Preserves the name of keys unless that key is already in use, in which case the key becomes the hierarchy of the object.
 * Example:
 * {
 *  test: 4
 *  big: {
 *    test: 5
 *    second: 6
 *  }
 * }
 * Will become
 * {
 *  test: 4
 *  big.test: 5
 *  second: 6
 * }
 *
 * @param {Object} originalObj  The object to flatten.
 * @returns {Object} A flattened object in the manner described above.
 */
export function flattenObjectOnly(originalObj) {
  const result = {};

  for (const i in originalObj) {
    if (typeof originalObj[i] === 'object' && !Array.isArray(originalObj[i])) {
      const temp = flattenObjectOnly(originalObj[i]);
      for (const j in temp) {
        if (j in result) {
          result[i + '.' + j] = temp[j];
        } else {
          result[j] = temp[j];
        }
      }
    } else {
      result[i] = originalObj[i];
    }
  }
  return result;
}

export const normalizeExternalUrl = (url) => {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return `https://${url}`;
  }
  return url;
};

export const removeHtmlFromString = (str) => {
  if (str && typeof str === 'string') {
    return str.replace(/<\/?[^>]+(>|$)/g, '');
  } else {
    return null;
  }
};

/**
 * Class representing a two-way map.
 * Allows mapping from key to value and from value to key.
 */
class TwoWayMap {
  /**
   * Creates an instance of TwoWayMap.
   * @param {Object} map - The initial map object.
   */
  constructor(map) {
    this.map = map;
    this.reverseMap = {};
    Object.keys(map).forEach((key) => {
      const value = map[key];
      this.reverseMap[value] = key;
    });
  }
  /**
   * Gets the value associated with the given key.
   * @param {string} key - The key to look up.
   * @returns {*} - The value associated with the key.
   */
  get(key) {
    return this.map[key];
  }

  /**
   * Gets the key associated with the given value.
   * @param {string} key - The value to look up.
   * @returns {*} - The key associated with the value.
   */
  revGet(key) {
    return this.reverseMap[key];
  }
}

export const USER_DEFAULT_FILTERS_TO_ACTIVE_FILTERS_MAP = new TwoWayMap({
  lineOfBusiness: 'line_of_business',
  resourceType: 'resource_type',
  region: 'region',
  carrier: 'carrier',
  fileType: 'document_type',
  label: 'labels',
});
