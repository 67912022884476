/**
 * Iterates through an object and its children to replace the value of src properties with the given text.
 * @param {Object} obj Rich text raw value object to be iterated through
 * @param {string} newSrc The new src value you would like in children objects
 */
export const updateRawValueWithPlaceholderImage = (obj, newSrc) => {
  // Base case: if obj is null or not an object, just return
  if (!obj || typeof obj !== 'object') return;

  // Check if the 'src' property exists in the object and is not undefined
  if ('src' in obj && 'data-file-id' in obj) obj.src = newSrc;

  // If the object has a 'children' property which is an array, recurse on its children
  if (Array.isArray(obj.children)) obj.children.forEach((child) => updateRawValueWithPlaceholderImage(child, newSrc));
};

/**
 * Goes through a html string, finds img tags and replaces the value of src properties with the given new src.
 * @param {string} htmlString Html string to be worked on
 * @param {string} newSrc The new src value you would like in img src
 * @returns {string} display_html with new src values in img src
 */
export const updateAllImageSrcInHTMLString = (htmlString, newSrc) => {
  return htmlString.replace(
    /(<img[^>]+data-file-id=['"](?!(undefined))([^'"]+)['"][^>]*src=['"])([^'"]+)(['"][^>]*>)/gi,
    `$1${newSrc}$5`,
  );
};

/**
 * Goes through a raw_value (json stringified editor state) string, finds img type elements and replaces the value of src properties with the pre-signed urls in attachments for every match between file ids.
 * @param {string} rawValue raw_value string to be worked on
 * @param {Array} attachments list of objects having file_id and url for each attachment
 * @returns {string} rawValue with attachment urls
 */
export const replaceRawValueWithAttachmentUrls = (rawValue, attachments) => {
  const data = JSON.parse(rawValue);

  data.root.children.forEach((paragraph) => {
    paragraph.children.forEach((element) => {
      if (element.type === 'image') {
        const attachment = attachments.find((att) => att.file_id === element['data-file-id']);
        if (attachment) {
          element.src = attachment.url;
        }
      }
    });
  });

  return JSON.stringify(data);
};

/**
 * Goes through a list of announcements, for each announcement finds img elements in raw_value and replaces the value of src properties with the pre-signed urls in announcement attachments for every match between file ids.
 * @param {Array} announcements list of announcemnts to be worked on
 */
export const replaceImagesInAnnouncementRawValuesWithAttachmentUrls = (announcements) => {
  announcements.forEach((announcement) => {
    announcement.rich_text_data.raw_value = replaceRawValueWithAttachmentUrls(
      announcement.rich_text_data.raw_value,
      announcement.attachments,
    );
  });
};

/**
 * Goes through the raw value in rich text editor state, extracts all the data-file-ids from it and based on the extracted data-file-ids filters out attachments which have been removed.
 * @param {string} rawValue raw_value string from the editor state
 * @param {Array} attachments list of attachments
 * @returns {Array} attachments filtered out based on data-file-ids in rawValue
 */
export function filterAttachments(rawValue, attachments) {
  const parsedRawValue = JSON.parse(rawValue);

  // Function to get all data-file-ids from the rawValue object
  const getDataFileIds = (node) => {
    let ids = [];
    if (node['data-file-id']) {
      ids.push(node['data-file-id']);
    }
    if (node.children) {
      node.children.forEach((child) => {
        ids = ids.concat(getDataFileIds(child));
      });
    }
    return ids;
  };

  const dataFileIds = getDataFileIds(parsedRawValue.root);

  return attachments.filter((attachment) => dataFileIds.includes(attachment.file_id));
}
