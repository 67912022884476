import React from 'react';
import {useLocation, Route} from 'react-router-dom';
import PropTypes from 'prop-types';

import useAuthStatus from 'hooks/useAuthStatus';
import PageLoading from 'components/loading/PageLoading';
import LocalizedRedirect from 'components/routing/LocalizedRedirect';
import RoutingContextProvider from 'components/routing/RoutingContextProvider';

/**
A route that will render only if the user is not authenticated.
If they are authenticated, they will be redirected to root (/).
*/
export default function UnauthenticatedRoute({component: Component, ...rest}) {
  const location = useLocation();
  const {isLoggedIn, isSAMLLogin, isRefreshingToken} = useAuthStatus();

  if (isRefreshingToken || isLoggedIn == null) {
    return <PageLoading />;
  }

  if (isLoggedIn && !isSAMLLogin) {
    return (
      <LocalizedRedirect
        to={{
          pathname: '/',
          state: {...location},
        }}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <RoutingContextProvider isAuthenticatedRoute={false}>
          <Component {...props} />
        </RoutingContextProvider>
      )}
    />
  );
}

UnauthenticatedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};
