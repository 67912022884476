import React from 'react';
import {useTranslation} from 'react-i18next';
import {Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';
import {translatePathname} from 'lib/url_localization';

/** Redirect to path under current language */
function LocalizedRedirect({to, ...rest}) {
  const {
    i18n: {language},
  } = useTranslation();

  return (
    <Redirect
      to={
        typeof to === 'string'
          ? translatePathname(to, language)
          : {
              ...to,
              pathname: translatePathname(to.pathname, language),
            }
      }
      {...rest}
    />
  );
}

LocalizedRedirect.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default LocalizedRedirect;
