import React from 'react';
import {sageTitle} from 'lib/constants';
import LoginAndRegistrationLayout from 'components/login/LoginAndRegistrationLayout';
import WaitingScreen from 'components/loading/WaitingScreen';

export default function PageLoading(props) {
  return (
    <LoginAndRegistrationLayout pageName={sageTitle}>
      <WaitingScreen />
    </LoginAndRegistrationLayout>
  );
}
