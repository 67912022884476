import mixpanel from 'mixpanel-browser';
import api from 'lib/api';
import * as jwt from 'jsonwebtoken';
import {AnalyticsEvent, userClicksLink} from 'lib/externalAnalytics/events';
import {ALERT_LABELS} from 'lib/constants';
import {isAnalyticsFeatureEnabled, shouldLogAnalyticsToConsole} from 'lib/externalAnalytics/utils';

const eventLogger = {
  /**
   * Tracks a user interaction and send the data to the analytics system
   * @param {string} name - The name/identifier of the event
   * @param {Object} data - Metadata about the event
   */
  track: (event) => {
    if (!event) {
      throw new Error('Expected event to be specified');
    } else if (!(event instanceof AnalyticsEvent)) {
      throw new Error('Expected event to be an instance of Analytics Event');
    }

    if (shouldLogAnalyticsToConsole()) {
      console.table({name: event.name, event_timestamp: Date(), ...event.data});
    }

    if (event.hasMissingData) {
      const keysForMissingData = event.getKeysForMissingData();
      console.warn(`Missing "${keysForMissingData.join(',')}" on "${event.name}" event`);
      api.internalAlert({
        label: ALERT_LABELS.ANALYTICS_EVENT_WITH_MISSING_DATA,
        eventName: event.name,
        keysForMissingData: keysForMissingData,
        data: event.data,
      });
    }

    if (!isAnalyticsFeatureEnabled()) {
      return;
    }

    mixpanel.track(event.name, event.data);
  },
  trackFromQueryParam: (trackToken) => {
    if (!trackToken) {
      throw new Error('Expected token to be passed in');
    }

    let trackData = null;

    try {
      trackData = jwt.decode(trackToken);
    } catch (error) {
      throw new Error(`Unable to parse tracking token ${error}`);
    }

    const trackProperties = trackData.properties;
    if (trackProperties) {
      const event = userClicksLink(trackData.properties);
      eventLogger.track(event);
    }
  },
};

export const {trackFromQueryParam} = eventLogger;
