import React, {createContext, useContext} from 'react';

import PropTypes from 'prop-types';

const DEFAULT_IS_AUTHENTICATED_ROUTE = false;

const RoutingContext = createContext({
  isAuthenticatedRoute: DEFAULT_IS_AUTHENTICATED_ROUTE,
});

export function useRoutingContext() {
  return useContext(RoutingContext);
}

export default function RoutingContextProvider({isAuthenticatedRoute, children}) {
  return (
    <RoutingContext.Provider
      value={{
        isAuthenticatedRoute,
      }}
    >
      {children}
    </RoutingContext.Provider>
  );
}

RoutingContextProvider.propTypes = {
  isAuthenticatedRoute: PropTypes.bool,
  children: PropTypes.node,
};
