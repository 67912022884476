import React, {createContext, useContext, useMemo} from 'react';
import PropTypes from 'prop-types';
import sdkServiceWrapper from 'components/featureFlags/launchDarklySDKService/SDKServiceWrapper';
import {useFlags} from 'launchdarkly-react-client-sdk';

export const FeatureFlagContext = createContext({});

export const FeatureFlagContextProvider = ({children, overrideFlags}) => {
  const allFeatureFlags = useFlags();
  const flags = useMemo(
    () => ({
      ...allFeatureFlags,
      ...overrideFlags,
    }),
    [allFeatureFlags, overrideFlags],
  );
  return <FeatureFlagContext.Provider value={flags}>{children}</FeatureFlagContext.Provider>;
};

FeatureFlagContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  overrideFlags: PropTypes.object,
};

export function useFeatureFlagContext() {
  return useContext(FeatureFlagContext);
}

export default sdkServiceWrapper(FeatureFlagContextProvider);
