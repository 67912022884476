// WARNING: Order of imports is important: https://www.npmjs.com/package/react-app-polyfill
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import 'unorm';

import React, {Suspense} from 'react';
import {LiveAnnouncer} from 'react-aria-live';
import ReactDOM from 'react-dom';
import {Helmet} from 'react-helmet';
import {BrowserRouter} from 'react-router-dom';

import App from 'App';
import PageLoading from 'components/loading/PageLoading';
import {sageTitle, ROOT_ELEMENT_ID} from 'lib/constants';
import 'i18n';
import 'wicg-inert';
import * as serviceWorker from 'serviceWorker';

ReactDOM.render(
  // eslint indent+react/jsx-indent rules seem to conflict over this construct
  // eslint-disable-next-line react/jsx-indent
  <BrowserRouter>
    <Helmet titleTemplate="%s" defaultTitle={sageTitle} />
    <LiveAnnouncer>
      <Suspense fallback={<PageLoading />}>
        <App />
      </Suspense>
    </LiveAnnouncer>
  </BrowserRouter>,
  document.getElementById(ROOT_ELEMENT_ID),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
