import {withLDProvider} from 'launchdarkly-react-client-sdk';

function sdkServiceWrapper(children) {
  const context = {
    kind: 'user',
    key: 'anonymous-sage-user',
    anonymous: true,
  };
  return withLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID,
    context: context,
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
  })(children);
}

export default sdkServiceWrapper;
