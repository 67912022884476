import * as jwt from 'jsonwebtoken';

class Authorization {
  VIEW_PAGE = 'portal:view';
  EDIT_PAGE = 'portal:edit';
  DELETE_RESOURCE = 'portal:delete';
  READ_DATA_REQUEST = 'data_request:read';

  RESOURCES = 'portal:resources';
  RESOURCE_MANAGEMENT = 'portal:resource_management';
  APP_ANNOUNCEMENTS = 'portal:app_announcements';
  USER_GROUPS = 'portal:user_groups';
  ADMIN = 'portal:admin';
  REPORTS = 'dialog_api:reports';

  validate(token) {
    // Front-end should not control access to data so validating expiration claim is sufficient.
    // All data in the front-end is exposed to the client; the back-end must fully validate token
    // before taking action or releasing data.
    const decoded = jwt.decode(token);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  }

  checkPermission(membership, resource, action) {
    // In some cases when a user is logged out there is a timing issue with the rerender and the membership could be undefined
    if (!membership) {
      return false;
    }

    const role = JSON.parse(membership.permissions);
    const index = role['Permissions'].findIndex((permission) => {
      const hasResource = permission['Resource'].indexOf(resource) !== -1 || permission['Resource'].indexOf('*') !== -1;
      const hasAction = permission['Action'].indexOf(action) !== -1 || permission['Action'].indexOf('*') !== -1;
      return hasResource && hasAction;
    });

    return index !== -1;
  }
}

export default new Authorization();
