import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  body: {
    width: '100%',
    maxWidth: 320,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 180,
  },
}));

function LoginAndRegistrationLayout(props) {
  const {pageName} = props;

  const classes = useStyles();

  return (
    <Grid aria-label={pageName} role="main" className={classes.body}>
      {props.children}
    </Grid>
  );
}

LoginAndRegistrationLayout.propTypes = {
  pageName: PropTypes.string,
  children: PropTypes.node,
};

export default LoginAndRegistrationLayout;
