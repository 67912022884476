import {createTheme, ThemeProvider} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {THEME_LOCALE_MAP} from 'i18n';

import AppRouter from 'App.router';
import 'style/App.css';
import defaultTheme from 'defaultTheme';

import {isEnvironmentProduction} from 'lib/utils';

import FeatureFlagContextProvider from 'contexts/FeatureFlagContextProvider';

/** Main app component */
function App() {
  const {i18n} = useTranslation();
  const {language} = i18n;

  const muiTheme = useMemo(() => createTheme(defaultTheme, THEME_LOCALE_MAP[language]), [language]);

  if (!isEnvironmentProduction()) {
    console.log('Running in environment:', process.env.NODE_ENV);
  }

  return (
    <ThemeProvider theme={muiTheme}>
      <FeatureFlagContextProvider>
        <AppRouter />
      </FeatureFlagContextProvider>
    </ThemeProvider>
  );
}

App.propTypes = {
  isLoggedIn: PropTypes.bool,
};

export default App;
