import PropTypes from 'prop-types';

import {useFormik} from 'formik';

/** Get error message */
function getErrorMessage(errors) {
  if ('errorText' in errors) {
    return errors.errorText;
  }

  if (Object.values(errors).length > 0) {
    return Object.values(errors)[0];
  }

  return '';
}

/** Check if the field has an error or just has not been touched */
function isError(formik, field) {
  return !!formik.errors[field] && !!formik.touched[field];
}

isError.PropTypes = {
  formik: PropTypes.instanceOf(useFormik).isRequired,
  field: PropTypes.string.isRequired,
};

/** Check all required fields have been filled in */
function allRequiredTouched(formik, fieldNames) {
  return fieldNames.every((requiredField) => {
    return !!formik.touched[requiredField];
  });
}

allRequiredTouched.PropTypes = {
  formik: PropTypes.instanceOf(useFormik).isRequired,
  field: PropTypes.arrayOf(PropTypes.string).isRequired,
};

class DuplicateValueError extends Error {
  constructor(message) {
    super(message);
    this.name = 'DuplicateValueError';
  }
}

class ResponseException {
  constructor(errorResponse) {
    this.data = errorResponse.data;
    this.status = errorResponse.status;
  }
}

export default getErrorMessage;
export {getErrorMessage, isError, allRequiredTouched, DuplicateValueError, ResponseException};
