import moment from 'moment';
import {
  MAX_REFRESH_TOKEN_WAIT_TIME,
  REFRESH_TOKEN_ENDPOINT,
  REFRESH_TOKEN_FETCH_ERROR_CODE,
  REFRESH_TOKEN_WAIT_TIME,
} from 'lib/constants';
import api from 'lib/api';

class TokenRefresh {
  waitForRefresh() {
    return new Promise((resolve, reject) => {
      const waitInterval = setInterval(() => {
        const fetchingTimestamp = localStorage.getItem('refreshingToken');
        if (!fetchingTimestamp) {
          clearInterval(waitInterval);
          resolve();
        } else if (moment().diff(moment(fetchingTimestamp), 'milliseconds') > MAX_REFRESH_TOKEN_WAIT_TIME) {
          // fetching the token failed, kick off giving it a try again
          // it is unlikely this would yield anything but a error, but may as well cover a extreme edge case
          this.refreshWorker();
        }
      }, REFRESH_TOKEN_WAIT_TIME);
    });
  }

  async refresh() {
    // no refresh token, don't attempt to refresh
    if (!api.getRefreshToken()) {
      return;
    }

    // a refreshed token is already being fetched, instead return a promise that gets resolved
    // once the fetch is complete
    const fetchingTimestamp = localStorage.getItem('refreshingToken');

    // If there is no time ongoing token refresh, it timed out some how, or the user refreshed their browser during a
    // token refresh then get a new token, otherwise wait for the process to finish
    if (fetchingTimestamp && moment().diff(moment(fetchingTimestamp), 'milliseconds') <= MAX_REFRESH_TOKEN_WAIT_TIME) {
      return this.waitForRefresh();
    }

    await this.refreshWorker();
  }

  async refreshWorker() {
    localStorage.setItem('refreshingToken', moment().toJSON());

    try {
      const refreshResponse = await api._fetch(
        process.env.REACT_APP_AUTH_BASE_URL,
        REFRESH_TOKEN_ENDPOINT,
        'POST',
        {
          refresh_token: api.getRefreshToken(),
        },
        false,
        false,
      );

      // Update the stored tokens
      const newToken = refreshResponse.data.token;
      const newRefreshToken = refreshResponse.data.refresh_token;
      api.setStoredTokens(newToken, newRefreshToken);

      // Let any call waiting on the refresh know that we are done
      localStorage.removeItem('refreshingToken');
    } catch (errorResponse) {
      // the refresh token has already been used, and the user has already been signed out.
      if (errorResponse.status === REFRESH_TOKEN_FETCH_ERROR_CODE) {
        api.clearStorage();
      }

      console.log(`+++ Refresh token failed with error: ${errorResponse.status}`);
      api.callLogoutCallback();

      // While this call failed with errors, we should still clean up the fetching timestamp
      localStorage.removeItem('refreshingToken');

      // Pass the error back up the chain
      throw errorResponse;
    }
  }
}

export const tokenRefresh = new TokenRefresh();

export default tokenRefresh;
