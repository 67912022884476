import React from 'react';
import PropTypes from 'prop-types';
import {Route, useLocation} from 'react-router-dom';

import LocalizedRedirect from 'components/routing/LocalizedRedirect';
import PageLoading from 'components/loading/PageLoading';
import RoutingContextProvider from 'components/routing/RoutingContextProvider';
import useAuthStatus from 'hooks/useAuthStatus';

/**
A route that will render only if the user is authenticated.
If they are not authenticated, they will be redirected to log in (/log_in).
*/
function AuthenticatedRoute({component: Component, ...rest}) {
  const location = useLocation();
  const {isLoggedIn, isSAMLLogin} = useAuthStatus();

  if (isLoggedIn == null) {
    return <PageLoading />;
  }

  if (!isLoggedIn || isSAMLLogin) {
    return (
      <LocalizedRedirect
        to={{
          pathname: '/log_in',
          state: {...location},
        }}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <RoutingContextProvider isAuthenticatedRoute={true}>
          <Component {...props} />
        </RoutingContextProvider>
      )}
    />
  );
}

AuthenticatedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default AuthenticatedRoute;
