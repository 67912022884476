import {createTheme} from '@material-ui/core/styles';
import {BUTTON_HOVER_COLOR_LIGHT, PRIMARY_COLOR, textStyles} from 'lib/text_styles';

function round(value) {
  return Math.round(value * 1e5) / 1e5;
}

const fontFamily = 'Source Sans Pro, sans-serif';

// Please Note: This uses the default letterSpacing values from MUI. Because we are switching the font
// family before creating the theme, the letterSpacing has to be set.
// See https://github.com/mui/material-ui/issues/24889 for more information.
const buildVariant = (size, letterSpacing) => ({
  fontFamily,
  letterSpacing: `${round(letterSpacing / size)}em`,
});

const typographyVariants = {
  h1: buildVariant(96, -1.5),
  h2: buildVariant(60, -0.5),
  h3: buildVariant(48, 0),
  h4: buildVariant(34, 0.25),
  h5: buildVariant(24, 0),
  h6: buildVariant(20, 0.15),
  subtitle1: buildVariant(16, 0.15),
  subtitle2: buildVariant(14, 0.1),
  body1: buildVariant(16, 0.15),
  body2: buildVariant(14, 0.15),
  button: buildVariant(14, 0.4),
  caption: buildVariant(12, 0.4),
  overline: buildVariant(12, 1),
};

export default createTheme({
  name: 'def',
  button: {
    color: 'yellow',
  },
  typography: {
    useNextVariants: true,
    ...typographyVariants,
  },
  logo: {
    color: 'red',
  },
  palette: {
    primary: {
      main: '#2E5BFF',
    },
  },
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: 'red',
        '&$error': {
          color: 'red',
        },
      },
    },
    MuiButton: {
      textPrimary: {
        color: PRIMARY_COLOR,
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: BUTTON_HOVER_COLOR_LIGHT,
        },
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: PRIMARY_COLOR,
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: textStyles.buttonStyles.primary.background,
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: textStyles.buttonStyles.primary.hover.background,
        },
      },
      current: {
        color: PRIMARY_COLOR,
      },
    },
    MuiPickersYear: {
      root: {
        '&:focus': {
          color: PRIMARY_COLOR,
        },
      },
      yearSelected: {
        color: PRIMARY_COLOR,
      },
    },
  },
});
