export const sageTitle = 'Sage'; // this assumed to be language agnostic

export const ROOT_ELEMENT_ID = 'root';

// Items that should be cleared from local storage upon termination of app
export const STORAGE_TO_CLEAR = ['token', 'refreshToken', 'profile'];

export const DETACHED_DOCUMENT_VIEWER_STORAGE_PREFIX = 'doc_viewer';

// This defines how often to check if a new token has been collected, in ms
export const REFRESH_TOKEN_WAIT_TIME = 100;

// Other constants for refreshing the token
export const REFRESH_TOKEN_FETCH_ERROR_CODE = 400;
export const REFRESH_TOKEN_ENDPOINT = '/token/refresh';

// The timeout for the refresh wait and check loop
export const MAX_REFRESH_TOKEN_WAIT_TIME = 20000;

// Front end only `All` Line of business
export const ALL_LINE_OF_BUSINESS = 'all';

export const ALERT_LABELS = {
  PDFTRON_WEB_VIEWER: {
    LOAD_ERROR: 'PDFTronWebViewer.loadError',
  },
  ANALYTICS_EVENT_WITH_MISSING_DATA: 'AnalyticsEventMissingData',
  INTERNAL_ANALYTICS_EVENT_FAILED: 'InternalAnalyticsEventFailed',
  LINK_TRACKING_FAILED: 'LinkTrackingFailed',
};

export const SUPPORTED_CONTENT_TYPES = [
  'application/pdf',
  '.pdf',
  '.docx',
  '.xlsx',
  '.doc',
  '.xls',
  'application/msword',
  'application/vnd.ms-excel',
  '.pptx',
  '.dotm',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
];

export const MILLISECONDS_IN_SECOND = 1000;

export const HTTP_RESPONSE_STATUS_CODES = {
  OK: 200,
  // Client Errors
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  UNPROCESSABLE_CONTENT: 422,
  // Server Errors
  INTERNAL_SERVER_ERROR: 500,
};

export const HTTP_MIME_TYPE = {
  XML: 'application/xml',
};

export const SEARCH_SESSION_FETCH_LIMIT = 7;
export const FAVORITES_FETCH_LIMIT = 100;
export const FAVORITES_SORT_BY_TYPES = {
  newest: 'newest',
  oldest: 'oldest',
};
export const SECTIONS_FETCH_LIMIT = 100;

export const UNASSIGNED_SECTION = {
  id: -1,
  name: 'Not Assigned',
  readOnly: true,
};

// FUTURE PBP-10653: Create synonyms in backend for document types
export const DOCUMENT_TYPE_SYNONYMS = {
  docx: ['doc'],
  xlsx: ['xls'],
};

export const CONTENT_TYPE_ENUM = {
  PDF: 'pdf',
  DOC: 'doc',
  DOCX: 'docx',
  XLS: 'xls',
  XLSX: 'xlsx',
  PPTX: 'pptx',
  SAGE_LINKS: 'sageLinks',
  SAGE_NOTES: 'sageNotes',
  UNDEFINED_TYPE: 'undefinedType',
  // Supported by PDFTron, but not our extraction process
  HDP: 'hdp',
  JPG: 'jpg',
  JPEG: 'jpeg',
  PNG: 'png',
  MP3: 'mp3',
  WAV: 'wav',
  GIF: 'gif',
  BMP: 'bmp',
  FLAC: 'flac',
  // SHOULDDO: (PBP-10664) Enable these as viewable, this requires a upgrade to our PDFTron license.
  MP4: 'mp4',
  OGG: 'ogg',
  WEBM: 'webm',
  // Would require additional work to support
  TIF: 'tif',
  TIFF: 'tiff',
  // Supported by server side viewer, but not client side
  PUB: 'pub',
  HTML: 'html',
  HTM: 'htm',
  MHT: 'mht',
  DWG: 'dwg',
  DXF: 'dxf',
  DGN: 'dgn',
  RVT: 'rvt',
  DWF: 'dwf',
  WPF: 'wpf',
  WMF: 'wmf',
  EMF: 'emf',
  JP2: 'jp2',
  JPC: 'jpc',
  RTF: 'rtf',
  ODT: 'odt',
  ODS: 'ods',
  ODP: 'odp',
  MSG: 'msg',
  TXT: 'txt',
  SVG: 'svg',
  // Would require custom server
  XOD: 'xod',
  XAML: 'xaml',
  XPS: 'xps',
  OXPS: 'oxps',
  XML: 'xml',
  // Not supported by our extraction or PDFTron
  DOTM: 'dotm',
  DOTX: 'dotx',
  XLMS: 'xlms',
};

export const AUDIOVISUAL_MIME_TYPES_ENUM = {
  [CONTENT_TYPE_ENUM.MP3]: 'audio/mpeg',
  [CONTENT_TYPE_ENUM.WAV]: 'audio/wav',
  [CONTENT_TYPE_ENUM.FLAC]: 'audio/flac',
  [CONTENT_TYPE_ENUM.MP4]: 'video/mp4',
  [CONTENT_TYPE_ENUM.OGG]: 'video/ogg',
  [CONTENT_TYPE_ENUM.WEBM]: 'video/webm',
};

export const VIDEO_VIEWER_FILE_TYPES = [CONTENT_TYPE_ENUM.MP4, CONTENT_TYPE_ENUM.OGG, CONTENT_TYPE_ENUM.WEBM];

export const AUDIO_VIEWER_FILE_TYPES = [CONTENT_TYPE_ENUM.MP3, CONTENT_TYPE_ENUM.WAV, CONTENT_TYPE_ENUM.FLAC];

export const AUDIOVISUAL_VIEWER_FILE_TYPES = [...AUDIO_VIEWER_FILE_TYPES, ...VIDEO_VIEWER_FILE_TYPES];

export const VIEWABLE_CONTENT_TYPES = [
  CONTENT_TYPE_ENUM.PDF,
  CONTENT_TYPE_ENUM.DOC,
  CONTENT_TYPE_ENUM.DOCX,
  CONTENT_TYPE_ENUM.XLS,
  CONTENT_TYPE_ENUM.XLSX,
  CONTENT_TYPE_ENUM.PPTX,
  CONTENT_TYPE_ENUM.HDP,
  CONTENT_TYPE_ENUM.JPEG,
  CONTENT_TYPE_ENUM.JPG,
  CONTENT_TYPE_ENUM.PNG,
  CONTENT_TYPE_ENUM.GIF,
  CONTENT_TYPE_ENUM.BMP,
  CONTENT_TYPE_ENUM.SAGE_LINKS,
  CONTENT_TYPE_ENUM.SAGE_NOTES,
  ...AUDIOVISUAL_VIEWER_FILE_TYPES,
];

export const GROUP_BY_ENUM = {
  resourceType: {dataKey: 'resource_type', titleKey: 'components.groupByMenu.resourceType'},
  region: {dataKey: 'region', titleKey: 'components.groupByMenu.region'},
  author: {dataKey: 'carrier', titleKey: 'components.groupByMenu.author'},
};
export const SORT_BY_ENUM = {
  relevance: {dataKey: 'relevance', titleKey: 'components.sortByMenu.relevance'},
  popularity: {dataKey: 'popularity', titleKey: 'components.sortByMenu.popularity'},
  alphabetical: {
    dataKey: 'title',
    titleKey: 'components.sortByMenu.alphabetical',
    columnName: 'components.listView.columns.name',
  },
  author: {
    dataKey: 'carrier',
    titleKey: 'components.sortByMenu.author',
    columnName: 'components.listView.columns.author',
  },
  date: {
    dataKey: 'effective_date',
    titleKey: 'components.sortByMenu.date',
    columnName: 'components.listView.columns.effectiveDate',
  },
  lineOfBusiness: {
    dataKey: 'line_of_business',
    titleKey: 'components.sortByMenu.lineOfBusiness',
    columnName: 'components.listView.columns.lineOfBusiness',
  },
  region: {
    dataKey: 'region',
    titleKey: 'components.sortByMenu.region',
    columnName: 'components.listView.columns.regions',
  },
  resourceType: {
    dataKey: 'resource_type',
    titleKey: 'components.sortByMenu.resourceType',
    columnName: 'components.listView.columns.type',
  },
};

export const DOCUMENT_PREVIEW_CARD_TAG_DISPLAY_ORDER = ['effective_date', 'line_of_business', 'resource_type'];

export const DOCUMENT_VIEWER_TAG_DISPLAY_ORDER = ['line_of_business', 'resource_type'];
export const LINKS_GROUP_KEY = 'Links';
export const DOCUMENTS_GROUP_KEY = 'Documents';

export const PAGE_PATHS = {
  analytics: '/analytics',
  announcements: '/announcements',
  dashboard: '/dashboard',
  favorites: '/favorites',
  links: '/links',
  logIn: '/log_in',
  mySage: '/my_sage',
  userSnippets: '/user_snippets',
  collection: '/collection',
  collections: '/collections',
  resourceManagement: '/resource_management',
  resources: '/resources',
  results: '/results',
  support: '/support',
  users: '/users',
  knowledge: '/knowledge',
};
export const VISIBLE_PAGE_NAMES = {
  dashboard: 'dashboard',
  my_sage: 'my_sage',
  user_snippets: 'user_snippets',
  collections: 'collections',
  resources: 'resources',
  announcements: 'announcements',
  users: 'users',
  knowledge: 'knowledge',
};

export const LIST_VIEW_ROWS_PER_PAGE_OPTIONS = [10, 20, 40];
export const DEFAULT_LIST_VIEW_ROWS_PER_PAGE_OPTIONS = [5, 10, 20];
export const LINKS_LIST_VIEW_ROWS_PER_PAGE_OPTIONS = [5, 10, 20, 40];
export const DEFAULT_TEAM_SNIPPETS_ROWS_PER_PAGE_OPTIONS = [10, 25, 50];
export const DEFAULT_PAGE_SIZE = 20;
export const FILTER_OTHER_CATEGORY_ID = -1;

export const RECENTLY_VIEWED_SEARCH_LIMIT = 10;
export const RECENTLY_VIEWED_TYPES = {DOCUMENT: 'document', EXTERNAL_LINK: 'external_link'};

export const HOW_ANALYTICS_OPTIONS = {
  'Recently Viewed': 'recently_viewed',
  'Recent Searches': 'recent',
  'Relevant Results': 'relevant',
};
export const SEARCH_RESULT_TYPES = {
  GRID: 'grid',
  LIST: 'list',
};

export const LIST_VIEW_SORTING_ORDER = ['desc', 'asc'];

export const MY_SNIPPETS_SORT_BY_TYPES = {
  newest: {titleKey: 'components.userSnippets.mySnippetsSortByMenu.newest', dataKey: 'newest'},
  oldest: {titleKey: 'components.userSnippets.mySnippetsSortByMenu.oldest', dataKey: 'oldest'},
};

export const COPY_SNIPPET_TEXT_SUCCESS_TOAST_TIMEOUT = 5000;
export const SHARE_LINK_COPY_SUCCESS_TOAST_TIMEOUT = 5000;
export const TOAST_NAVIGATION_MENU_OFFSET = '119px';
export const TOAST_HIDDEN_NAVIGATION_MENU_OFFSET = '24px';

export const POINTER_DIRECTIONS = {
  up: 'up',
  down: 'down',
};

export const ANALYTICS_SOURCES = {
  FAVORITES: 'favorites',
  RESOURCES: 'resources_page',
  TEAM_SNIPPETS: 'team_snippets',
  MY_SNIPPETS: 'my_snippets',
  COLLECTIONS: 'collections',
  DASHBOARD: 'dashboard',
};

export const ANALYTICS_HOW_REASONS = {
  DASHBOARD_RECENTLY_VIEWED: 'dashboard_recently_viewed',
};

// Actions for the u_content_use analytics event
export const CONTENT_EVENT_ACTIONS = {
  SHARE: 'share',
  OPEN: 'open',
};

// Types for the u_content_use analytics event
export const CONTENT_EVENT_TYPES = {
  USER_SNIPPET: 'snippetUser',
  TEAM_SNIPPET: 'snippetTeam',
  VIDEO: 'video',
  AUDIO: 'audio',
};

// Actions for u_document_actions analytics event
export const DOCUMENT_EVENT_ACTIONS = {
  snippetIntro: 'snippet_intro_clicked',
  highlightMenu: 'highlight_menu_shown',
  textCopied: 'text_copied',
};

export const DOCUMENT_STATES = {
  ACTIVE: 'ACTIVE',
  PENDING: 'PENDING',
};

export const DEFAULT_USER_ROLES = {
  USER: 'User',
  ADMIN: 'Admin',
  CONTENT_EDITOR: 'Content Editor',
  SUPER_ADMIN: 'Super Admin',
  KNOWLEDGE_ADMIN: 'Knowledge Admin',
  SUBJECT_MATTER_EXPERT: 'Subject Matter Expert',
};

// Offset of the PDFTron viewer iframe from the top-left corner of the window
export const PDFTRON_VIEWER_TOP_OFFSET = 214;
export const PDFTRON_VIEWER_LEFT_OFFSET = 110;

export const KEY_EVENT_VALUES = {
  ESCAPE: 'Escape',
  ENTER: 'Enter',
  TAB: 'Tab',
  KEYK: 'KeyK',
  ARROWUP: 'ArrowUp',
  ARROWDOWN: 'ArrowDown',
  SPACEBAR: ' ',
};

export const MOUSE_EVENT_VALUES = {
  MOUSE_MOVE: 'mousemove',
  MOUSE_UP: 'mouseup',
};

export const DOM_TAG_NAMES = {
  BUTTON: 'BUTTON',
};

// Icon Sizes
export const SMALL_SQUARE_ICON = 16;
export const SMALL_CLEAR_ICON = 22;

export const PAGINATION_TYPES = {
  PAGE: 'page',
  TABLE: 'table',
};

export const AUTHENTICATION_METHODS = {
  SSO: 'sso',
  PASSWORD: 'password',
};

export const FILTER_CATEGORY_MAPPING = {
  authors: 'carrier',
  labels: 'labels',
  lines_of_business: 'line_of_business',
  regions: 'region',
  resource_types: 'resource_type',
};

export const RICH_TEXT_EDITOR_RAW_FORMAT = {
  plaintext: 'plaintext',
  lexical: 'lexical',
};

// Knowledge Page
export const KNOWLEDGE_PAGE_SIZE = 20;
export const KNOWLEDGE_CARD_DATE_FORMAT_STRING = 'dddd, MMMM D';

// Test IDs
export const LOAD_MORE_FETCHING_TEST_ID = 'LOAD_MORE_FETCHING_TEST_ID';
export const LOAD_MORE_PROGRESS_TEST_ID = 'LOAD_MORE_PROGRESS_TEST_ID';
export const LOAD_MORE_BUTTON_TEST_ID = 'LOAD_MORE_BUTTON_TEST_ID';

export const QUESTION_ANSWER_EMPTY_CONTENTS_TEST_ID = 'QUESTION_ANSWER_EMPTY_CONTENTS_TEST_ID';
export const QUESTION_ANSWER_LIST_TEST_ID = 'QUESTION_ANSWER_LIST_TEST_ID';

// Announcements
export const ANNOUNCEMENT_DATE_RANGE_FILTERS = {
  LAST_7: 'last_7',
  LAST_30: 'last_30',
  ALL: 'all',
  PAST_WEEK: 'pastWeek',
  DAYS_30: '30days',
  DAYS_90: '90days',
  DATE_RANGE: 'dateRange',
};

export const ANNOUNCEMENT_PAGE_SIZE = 50;

export const UPLOAD_SIZE_CAP = 5 * 1000000; // 5MB

export const ANNOUNCEMENT_DATE_RANGE_FILTER_ON_SUBMIT_TIMEOUT = 100;

export const MAX_PAGE_WIDTH = '1280px';

export const ANNOUNCEMENT_CAROUSEL_CARD_LARGE_SCREEN_HEIGHT = '312px';
export const ANNOUNCEMENT_CAROUSEL_CARD_SMALL_SCREEN_HEIGHT = '209px';
export const MINIMUM_DASHBOARD_SCREEN_WIDTH = '1080px';

// Announcement path constants
export const ANNOUNCEMENT_PATH_ARGUMENT_NAME = 'announcement_id';
export const ANNOUNCEMENT_SOURCE_ARGUMENT_NAME = 'source';

export const MAXIMUM_DEFAULT_FILTERS_SELECTED_BADGE = 99;
