/**
 * MixPanel specific implementation details of our external analytics systems.
 */
import * as mixpanel from 'mixpanel-browser';
import {isAnalyticsFeatureEnabled} from 'lib/externalAnalytics/utils';

if (isAnalyticsFeatureEnabled()) {
  mixpanel.init(process.env.REACT_APP_MIX_PANEL_PROJECT_TOKEN, {
    api_host: `${process.env.REACT_APP_DIALOG_BASE_URL}/mixpanel`,
  });
}

const wrapper = {
  /**
   * All the groups defined in MixPanel.
   * The values represent "group_key" in MixPanel that connects event data for Group Analytics.
   */
  GROUPS: {
    APP_KEY: 'appKey',
  },

  _isValidGroup: function (value) {
    return Object.values(this.GROUPS).includes(value);
  },

  identifyUser: function (id) {
    if (!isAnalyticsFeatureEnabled()) {
      return;
    }

    if (id === undefined) {
      throw new Error('id must be specified');
    }

    mixpanel.identify(id);
  },

  resetUserProfile: function () {
    if (!isAnalyticsFeatureEnabled()) {
      return;
    }

    mixpanel.reset();
  },

  /**
   * Creates or updates a user profile in the analytics systems.
   * @param {Object} data
   * @param {String} primaryUserId - The primary user ID (email address) of the user.
   * @param {String} email - The email of the user.
   * @param {String} firstName - The first name of the user.
   * @param {String} lastName - The last name of the user.
   * @param {Array[String]} userGroups - The list of user groups the user belongs to.
   * @param {String} role - The role assigned to this user.
   * @returns
   */
  saveUserProfile: function ({primaryUserId, email, firstName, lastName, userGroups, role}) {
    if (!isAnalyticsFeatureEnabled()) {
      return;
    }

    mixpanel.people.set({primaryUserId, email, firstName, lastName, userGroups, role});
  },

  _addUserToGroup: function (groupKey, groupID) {
    if (!isAnalyticsFeatureEnabled()) {
      return;
    }

    if (!this._isValidGroup(groupKey)) {
      throw new Error('Invalid groupKey provided.');
    }

    mixpanel.set_group(groupKey, groupID);
  },

  _updateGroupProfile: function (groupKey, groupID, props) {
    if (!isAnalyticsFeatureEnabled()) {
      return;
    }

    if (!this._isValidGroup(groupKey)) {
      throw new Error('Invalid groupKey provided.');
    }

    if (!groupID) {
      throw new Error('GroupID must be specified');
    }

    if (!props) {
      throw new Error('Expected props to be specified');
    } else if (typeof props !== 'object') {
      throw new Error('Expected props to be an object');
    }

    mixpanel.get_group(groupKey, groupID).set(props);
  },

  /**
   * Associated the current mixpanel user with an app. Will update the app group profile, if needed
   * @param {Object} app
   */
  // eslint-disable-next-line camelcase
  setApp: function ({app_id, app_type, app_sub_type, description, customer_name}) {
    this._addUserToGroup(this.GROUPS.APP_KEY, app_id);

    this._updateGroupProfile(this.GROUPS.APP_KEY, app_id, {
      /* eslint-disable camelcase */
      $name: app_id,
      app_type,
      app_sub_type,
      description,
      customer_name,
      /* eslint-enable camelcase */
    });
  },

  /**
   * Used to clear any analytics for this user.
   * Note that this just disables further logging despite the bit about delete_user below.
   */
  doNotTrack: function () {
    mixpanel.opt_out_tracking({delete_user: true, clear_persistence: true});
  },
};

export default wrapper;
