import {useLocation} from 'react-router-dom';

import auth from 'lib/authorization';
import api from 'lib/api';
import {useEffect, useState} from 'react';

function useAuthStatus() {
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(api.isLoggedIn());

  // If there is a token in the params and it is valid, then it is a log in attempt via sso.
  // This is to cover the situation where a user attempts to log into a account while another
  // account is already logged in, this will override the logged in status and change over accounts
  const potentialToken = new URLSearchParams(location.search).get('token');
  const isSAMLLogin = potentialToken ? auth.validate(potentialToken) : false;

  useEffect(() => {
    const heartBeat = setInterval(() => setIsLoggedIn(api.isLoggedIn()), 1000);
    return () => clearInterval(heartBeat);
  }, [setIsLoggedIn]);

  return {isLoggedIn, isSAMLLogin};
}

export default useAuthStatus;
