import {enUS, frFR} from '@material-ui/core/locale';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import moment from 'moment';
import 'moment/locale/fr';
import {initReactI18next} from 'react-i18next';

const LANGUAGE_CODES = {
  ENGLISH: 'en',
  FRENCH: 'fr',
};

const THEME_LOCALE_MAP = {
  [LANGUAGE_CODES.ENGLISH]: enUS,
  [LANGUAGE_CODES.FRENCH]: frFR,
};

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .on('languageChanged', function (language) {
    moment.locale(language);
  })
  .init({
    fallbackLng: LANGUAGE_CODES.ENGLISH,
    supportedLngs: [LANGUAGE_CODES.ENGLISH, LANGUAGE_CODES.FRENCH],
    nonExplicitSupportedLngs: false,
    load: 'languageOnly',
    cleanCode: true,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['path', 'localStorage', 'navigator'],
      lookupFromPathIndex: 0,
    },
  });

export default i18n;
export {LANGUAGE_CODES, THEME_LOCALE_MAP};
