import {isStageProduction} from 'lib/utils';

export function isAnalyticsFeatureEnabled() {
  return process.env.REACT_APP_IS_ANALYTICS_FEATURE_ENABLED === 'true';
}

export function shouldLogAnalyticsToConsole() {
  // we should never be logging analytics data in production; we do not want to expose PII
  return process.env.NODE_ENV !== 'production' && process.env.REACT_APP_ANALYTICS_CONSOLE_LOGGING === 'true';
}

/** Roughly check if the user is internal by checking if pronavigator is in their email
 * or if the stage/environment is not production. This is done to avoid any inclusion of
 * internal users in reports or the need for adding a filter repeatedly to remove them.
 * The production check is to allow for internal users/testing to be unaffected. E.g.
 * being able to test locally or on test sites.
 * @returns {boolean} if the user is associated to ProNavigator
 */
export function shouldTrackUser(primaryUserId, userEmail) {
  const pronavRegex = /.*@pronavigator.*/;
  const primaryIdIncludesPronav = pronavRegex.test((primaryUserId || '').toLowerCase());
  const emailIncludesPronav = pronavRegex.test((userEmail || '').toLowerCase());

  return !isStageProduction() || !(primaryIdIncludesPronav || emailIncludesPronav);
}

export function removeTrackParam(queryString) {
  if (queryString) {
    const parsedSearchParams = new URLSearchParams(queryString);
    parsedSearchParams.delete('track');
    return `?${parsedSearchParams.toString()}`;
  }
  return queryString;
}
