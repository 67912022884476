import {COLLECTION_SHEET_SORT_BY_ENUM} from 'lib/collections';

export const ADMIN_EVENT_TYPES = {
  quickLinks: 'quickLinks',
  uploadContent: 'uploadContent',
  downloadReport: 'downloadReport',
  teamSnippet: 'teamSnippet',
  collections: 'collections',
};

export const ADMIN_EVENT_ACTIONS = {
  menu: 'menu',
  download: 'download',
  upload: 'upload',
};

export const SNIPPET_NAVIGATION_ACTIONS = {
  tags: 'tags',
  sortByNew: 'sortByNew',
  sortByOld: 'sortByOld',
  changePage: 'changePage',
};

export const SNIPPET_NAVIGATION_TAB_NAMES = {
  MySnippets: 'MySnippets',
  TeamSnippets: 'TeamSnippets',
};

export const CONTENT_USE_TYPES = {
  Announcement: 'announcement',
  Document: 'document',
  ExternalLink: 'externalLink',
};

export const CONTENT_USE_ACTIONS = {
  Print: 'print',
  Open: 'open',
  Download: 'download',
  Share: 'share',
};

export const eventNames = {
  adminActions: 'u_admin_actions',
  addFavorite: 'u_favorite_add',
  documentClose: 'u_document_close',
  documentDetachClose: 'u_document_detached_close',
  documentDetachOpen: 'u_document_detached_open',
  documentNavigate: 'u_document_navigate',
  documentOpen: 'u_document_open',
  documentShareLinkRequest: 'u_document_share_link_request',
  externalLinkCreated: 'u_external_link_created',
  navigate: 'u_navigate_menu',
  userOpensNotification: 'u_notification_open',
  userOpensAnnouncement: 'u_announcement_open',
  userNavigatesSearchResults: 'u_documents_navigate',
  removeFavorite: 'u_favorite_remove',
  sageLoadsDocument: 's_loads_document',
  searchFilter: 'u_search_filter',
  searchQuery: 'u_search_query',
  searchResult: 's_search_result',
  userShareLinkOpen: 'u_share_link_open',
  userSwitchesLanguage: 'u_language_change',
  userDownloadsFile: 'u_download_file',
  userPrintsFile: 'u_print_file',
  userPrintDialogOpen: 'u_print_dialog_open',
  userOpensProfileMenu: 'u_profile_open',
  announcementPublished: 'u_announcement_published',
  userTogglesPreSearchFilters: 'u_filter_before_search',
  userClicksLink: 'u_link_clicked',
  useContent: 'u_content_use',
  documentActions: 'u_document_actions',
  snippetActions: 'u_snippet_actions',
  snippetNavigation: 'u_snippets_navigate',
  login: 'u_login',
  collectionSheetActions: 'u_collectionsheet_actions',
  collectionActions: 'u_collection_actions',
};

/** The userInfo object containing user-related information
 * @typedef {Object} UserInfo
 * @property {String} userInfo.primaryUserId The id (typically same as the email) of the primary user
 * @property {String} userInfo.email The email of the primary user
 * @property {String} userInfo.firstName The user's first name
 * @property {String} userInfo.lastName The user's last name
 * @property {Array<String>} userInfo.userGroups An array of the user groups the user belongs to
 * @property {String} userInfo.roleName The name of the role assigned to the user
 */

/**
 * Represents an event that will be tracked for analytics purposes
 */
export class AnalyticsEvent {
  // the name of the event
  name;
  // the data associated with the event
  data;

  constructor(name, data) {
    if (!name) {
      throw new Error('Expected name to be specified');
    }

    if (!data) {
      throw new Error('Expected data to be specified');
    } else if (data && typeof data !== 'object') {
      throw new Error('Expected data to be an object');
    }

    this.name = name;
    this.data = {
      ...data,
    };
  }

  // returns true if any of the required event data is undefined
  get hasMissingData() {
    return Object.values(this.data).some((value) => value === undefined);
  }

  // returns an array of all keys that have missing data
  getKeysForMissingData() {
    return Object.entries(this.data)
      .filter(([key, value]) => value === undefined)
      .map(([key]) => key);
  }
}

/**
 * When user navigates by clicking on navigation menu
 * @param {Object} eventData
 * @param {String} eventData.toPageName Represents the name of the page user is navigating to
 *
 * @returns AnalyticsEvent
 */
export function userNavigatesViaMenu({toPageName}) {
  return new AnalyticsEvent(eventNames.navigate, {toPageName});
}

/**
 * When user navigates by clicking a Dashboard item
 * @param {Object} eventData
 * @param {String} eventData.toPageName Represents the name of the page user is navigating to
 *
 * @returns AnalyticsEvent
 */
export function userNavigatesViaDashboard({toPageName}) {
  return new AnalyticsEvent(eventNames.navigate, {toPageName});
}

/**
 * When user navigates by clicking a notification
 * @param {Object} eventData
 * @param {String} eventData.toPageName Represents the name of the page user is navigating to
 *
 * @returns AnalyticsEvent
 */
export function userNavigatesViaNotification({toPageName}) {
  return new AnalyticsEvent(eventNames.navigate, {toPageName});
}

/**
 * When user opens list of notifications by clicking a notification pop up/button
 * @param {Object} eventData
 * @param {String} eventData.type Represents using which type notification was opened
 *
 * @returns AnalyticsEvent
 */
export function userOpensNotification({type}) {
  return new AnalyticsEvent(eventNames.userOpensNotification, {type});
}

/**
 * When user opens an announcement
 * @param {Object} eventData
 * @param {String} eventData.announcementTitle The title of the announcement
 * @param {String} eventData.announcementId The id of the announcement
 * @param {String} eventData.how Represents how the announcement was opened
 *
 * @returns AnalyticsEvent
 */
export function userOpensAnnouncementContentUsage({announcementId, announcementTitle, how}) {
  return new AnalyticsEvent(eventNames.useContent, {
    action: CONTENT_USE_ACTIONS.Open,
    contentType: CONTENT_USE_TYPES.Announcement,
    announcementId,
    announcementTitle,
    how,
  });
}

/**
 * When user opens an announcement
 * @param {Object} eventData
 * @param {String} eventData.how Represents how the announcement was opened
 * @param {String} eventData.announcementTitle The title of the announcement
 * @param {String} eventData.announcementId The id of the announcement
 *
 * @returns AnalyticsEvent
 */
export function userOpensAnnouncement({how, announcementTitle, announcementId}) {
  return new AnalyticsEvent(eventNames.userOpensAnnouncement, {how, announcementTitle, announcementId});
}

/**
 * When a user closes a document
 * @param {Object} eventData Data directly related to the event
 * @param {Number} eventData.documentId The ID of the the document loaded
 * @param {String} eventData.documentType The file type of the document (e.g. pdf, docx)
 * @param {Number} eventData.resourceId The Resource ID of the document loaded
 * @param {Date}   eventData.resourceEffectiveDate The effective date of the resource
 * @param {Number} eventData.resourceExternalVersion The version of the resource
 * @param {Number} eventData.resourceIdentityId The Resource Identity ID of the document loaded
 * @param {String} eventData.resourceIdentityMasterName The master name of the Resource Identity
 * @param {String} eventData.resourceIdentityAuthorName The author (equivalent to carrier) name of the Resource Identity
 * @param {String} eventData.resourceIdentityResourceType The resource type of the Resource Identity (e.g. 'Brochure', 'Forms')
 * @param {String} eventData.resourceIdentityLanguage The language used for the Resource Identity (e.g. 'en' for English)
 * @param {Number} eventData.documentOpenInSeconds The number of seconds the document was open
 * @returns AnalyticsEvent
 */
export function userClosesDocument({
  documentId,
  documentType,
  resourceId,
  resourceEffectiveDate,
  resourceExternalVersion,
  resourceIdentityId,
  resourceIdentityMasterName,
  resourceIdentityAuthorName,
  resourceIdentityResourceType,
  resourceIdentityLanguage,
  documentOpenInSeconds,
}) {
  return new AnalyticsEvent(eventNames.documentClose, {
    documentId,
    documentType,
    resourceId,
    resourceEffectiveDate,
    resourceExternalVersion,
    resourceIdentityId,
    resourceIdentityMasterName,
    resourceIdentityAuthorName,
    resourceIdentityResourceType,
    resourceIdentityLanguage,
    documentOpenInSeconds,
  });
}

/**
 *
 * @param {Object} eventData
 * @param {Number} eventData.numberOfExternalLinks Represents the number of external links that matched the query
 *
 * @returns AnalyticsEvent
 */
export function sageReturnsExternalLinks({numberOfExternalLinks}) {
  return new AnalyticsEvent(eventNames.searchResult, {numberOfExternalLinks});
}

/**
 *
 * @param {Object} eventData
 * @param {Number} eventData.numberOfDocuments Represents the number of documents returned
 * @param {Number} eventData.origin            Since this event can be triggered in multiple locations this lets us know where
 * @param {Number} eventData.searchSessionId   If this was a document search then include the returned ID
 *
 * @returns AnalyticsEvent
 */
export function sageReturnsDocuments({numberOfDocuments, origin, searchSessionId = null}) {
  return new AnalyticsEvent(eventNames.searchResult, {numberOfDocuments, origin, searchSessionId});
}

/**
 * When user opens an external link
 * @param {Object} eventData
 * @param {Number} eventData.externalLinkId The id of the external link opened
 * @param {String} eventData.externalLinkURL The url of the created external link.
 * @param {Number} eventData.ranking The rank of the external link clicked on
 * @param {String} eventData.how The source of how the external link open was triggered
 *
 * @returns AnalyticsEvent
 */
export function userOpensExternalLink({externalLinkId, externalLinkURL, ranking = null, how = null}) {
  return new AnalyticsEvent(eventNames.documentOpen, {
    externalLinkId,
    externalLinkURL,
    type: 'external_link',
    ranking,
    how,
  });
}

export function userOpensExternalLinkContentUsage({
  externalLinkTitle,
  externalLinkLanguage,
  action = CONTENT_USE_ACTIONS.Open,
  contentType = CONTENT_USE_TYPES.ExternalLink,
  externalLinkId,
  externalLinkURL,
  ranking = null,
  how = null,
}) {
  return new AnalyticsEvent(eventNames.useContent, {
    externalLinkTitle,
    externalLinkLanguage,
    action,
    contentType,
    externalLinkId,
    externalLinkURL,
    ranking,
    how,
  });
}

export function userOpensDocumentContentUsage({
  documentId,
  documentType,
  resourceId,
  resourceEffectiveDate,
  resourceExternalVersion,
  resourceIdentityId,
  resourceIdentityMasterName,
  resourceIdentityAuthorName,
  resourceIdentityResourceType,
  resourceIdentityLanguage,
  ranking = null,
}) {
  const eventData = {
    action: CONTENT_USE_ACTIONS.Open,
    contentType: CONTENT_USE_TYPES.Document,
    documentId,
    documentType,
    resourceId,
    resourceEffectiveDate,
    resourceExternalVersion,
    resourceIdentityId,
    resourceIdentityMasterName,
    resourceIdentityAuthorName,
    resourceIdentityResourceType,
    resourceIdentityLanguage,
  };

  if (ranking !== null) {
    eventData['ranking'] = ranking;
  }

  return new AnalyticsEvent(eventNames.useContent, eventData);
}

/**
 * When user opens a document
 * @param {Object} eventData
 * @param {Object} eventData All data involved with the event, e.g. documentId, resourceId
 * @param {Number} eventData.documentId The ID of the the document loaded
 * @param {String} eventData.documentType The file type of the document (e.g. pdf, docx)
 * @param {Date}   eventData.resourceEffectiveDate The effective date of the resource
 * @param {Number} eventData.resourceExternalVersion The version of the resource
 * @param {Number} eventData.resourceId The Resource ID of the document loaded
 * @param {Number} eventData.resourceIdentityId The Resource Identity ID of the document loaded
 * @param {String} eventData.resourceIdentityMasterName The master name of the resource identity
 * @param {String} eventData.resourceIdentityAuthorName The author name of the resource. In our system this is equivalent to 'carrier'
 * @param {String} eventData.resourceIdentityResourceType The type of resource (e.g. 'Forms', 'Brochure')
 * @param {String} eventData.resourceIdentityLanguage The language of the resource identity (e.g. 'en' for English)
 * @param {String} eventData.how The source of how the document open was triggered
 * @param {Number} [eventData.ranking] The rank of the document clicked on
 * @param {String} eventData.components The component that triggered the document open
 *
 *
 */
export function userOpensDocument({
  documentId,
  documentType,
  resourceId,
  resourceEffectiveDate,
  resourceExternalVersion,
  resourceIdentityId,
  resourceIdentityMasterName,
  resourceIdentityAuthorName,
  resourceIdentityResourceType,
  resourceIdentityLanguage,
  how,
  ranking = null,
  components = [],
  collectionSheetTitle = null,
}) {
  return new AnalyticsEvent(eventNames.documentOpen, {
    documentId,
    documentType,
    resourceId,
    resourceEffectiveDate,
    resourceExternalVersion,
    resourceIdentityId,
    resourceIdentityMasterName,
    resourceIdentityAuthorName,
    resourceIdentityResourceType,
    resourceIdentityLanguage,
    type: 'document',
    ranking,
    rankingSecondary: null,
    how,
    components,
    collectionSheetTitle,
  });
}

/**
 * When user opens a snippet within a document
 * @param {Object} eventData
 * @param {Object} eventData All data involved with the event, e.g. documentId, resourceId
 * @param {Number} eventData.documentId The ID of the the document loaded
 * @param {String} eventData.documentType The file type of the document (e.g. pdf, docx)
 * @param {Date}   eventData.resourceEffectiveDate The effective date of the resource
 * @param {Number} eventData.resourceExternalVersion The version of the resource
 * @param {Number} eventData.resourceId The Resource ID of the document loaded
 * @param {Number} eventData.resourceIdentityId The Resource Identity ID of the document loaded
 * @param {String} eventData.resourceIdentityMasterName The master name of the resource identity
 * @param {String} eventData.resourceIdentityAuthorName The author name of the resource. In our system this is equivalent to 'carrier'
 * @param {String} eventData.resourceIdentityResourceType The type of resource (e.g. 'Forms', 'Brochure')
 * @param {String} eventData.resourceIdentityLanguage The language of the resource identity (e.g. 'en' for English)
 * @param {String} eventData.how The source of how the document open was triggered
 * @param {Number} eventData.ranking The rank of the document clicked on
 * @param {Number} eventData.rankingSecondary The rank of the snippet within the document snippets
 *
 */
export function userOpensSnippet({
  documentId,
  documentType,
  resourceId,
  resourceIdentityId,
  resourceEffectiveDate,
  resourceExternalVersion,
  resourceIdentityMasterName,
  resourceIdentityAuthorName,
  resourceIdentityResourceType,
  resourceIdentityLanguage,
  how,
  ranking,
  rankingSecondary,
}) {
  return new AnalyticsEvent(eventNames.documentOpen, {
    documentId,
    documentType,
    resourceId,
    resourceEffectiveDate,
    resourceExternalVersion,
    resourceIdentityId,
    resourceIdentityMasterName,
    resourceIdentityAuthorName,
    resourceIdentityResourceType,
    resourceIdentityLanguage,
    type: 'snippet',
    ranking,
    rankingSecondary,
    how,
  });
}

export function userOpensSubResultContentUsage({
  action = CONTENT_USE_ACTIONS.Open,
  contentType = CONTENT_USE_TYPES.Document,
  documentId,
  documentType,
  resourceId,
  resourceIdentityId,
  resourceEffectiveDate,
  resourceExternalVersion,
  resourceIdentityMasterName,
  resourceIdentityAuthorName,
  resourceIdentityResourceType,
  resourceIdentityLanguage,
  how,
  ranking,
  rankingSecondary,
}) {
  return new AnalyticsEvent(eventNames.useContent, {
    action,
    contentType,
    documentId,
    documentType,
    resourceId,
    resourceEffectiveDate,
    resourceExternalVersion,
    resourceIdentityId,
    resourceIdentityMasterName,
    resourceIdentityAuthorName,
    resourceIdentityResourceType,
    resourceIdentityLanguage,
    ranking,
    rankingSecondary,
    how,
  });
}

/**
 * When a user adds a favorite
 * @param {Object} eventData Data directly related to the event
 * @param {Number} eventData.documentId The ID of the the document loaded
 * @param {String} eventData.documentType The file type of the document (e.g. pdf, docx)
 * @param {Number} eventData.resourceId The Resource ID of the document loaded
 * @param {Date}   eventData.resourceEffectiveDate The effective date of the resource
 * @param {Number} eventData.resourceExternalVersion The version of the resource
 * @param {Number} eventData.resourceIdentityId The Resource Identity ID of the document loaded
 * @param {String} eventData.resourceIdentityMasterName The master name of the Resource Identity
 * @param {String} eventData.resourceIdentityAuthorName The author (equivalent to carrier) name of the Resource Identity
 * @param {String} eventData.resourceIdentityResourceType The resource type of the Resource Identity (e.g. 'Brochure', 'Forms')
 * @param {String} eventData.resourceIdentityLanguage The language used for the Resource Identity (e.g. 'en' for English)
 * @returns AnalyticsEvent
 */
export function userAddsFavorite({
  documentId,
  documentType,
  resourceEffectiveDate,
  resourceExternalVersion,
  resourceId,
  resourceIdentityId,
  resourceIdentityMasterName,
  resourceIdentityAuthorName,
  resourceIdentityResourceType,
  resourceIdentityLanguage,
}) {
  return new AnalyticsEvent(eventNames.addFavorite, {
    documentId,
    documentType,
    resourceEffectiveDate,
    resourceExternalVersion,
    resourceId,
    resourceIdentityId,
    resourceIdentityMasterName,
    resourceIdentityAuthorName,
    resourceIdentityResourceType,
    resourceIdentityLanguage,
  });
}

/**
 * When a user removes a favorite
 * @param {Object} eventData All data involved with the event, e.g. documentId, resourceId
 * @param {Number} eventData.documentId The ID of the the document loaded
 * @param {String} eventData.documentType The file type of the document (e.g. pdf, docx)
 * @param {Date}   eventData.resourceEffectiveDate The effective date of the resource
 * @param {Number} eventData.resourceExternalVersion The version of the resource
 * @param {Number} eventData.resourceId The Resource ID of the document loaded
 * @param {Number} eventData.resourceIdentityId The Resource Identity ID of the document loaded
 * @param {String} eventData.resourceIdentityMasterName The master name of the resource identity
 * @param {String} eventData.resourceIdentityAuthorName The author name of the resource. In our system this is equivalent to 'carrier'
 * @param {String} eventData.resourceIdentityResourceType The type of resource (e.g. 'Forms', 'Brochure')
 * @param {String} eventData.resourceIdentityLanguage The language of the resource identity (e.g. 'en' for English)
 * @returns AnalyticsEvent
 */
export function userRemovesFavorite({
  documentId,
  documentType,
  resourceEffectiveDate,
  resourceExternalVersion,
  resourceId,
  resourceIdentityId,
  resourceIdentityMasterName,
  resourceIdentityAuthorName,
  resourceIdentityResourceType,
  resourceIdentityLanguage,
}) {
  return new AnalyticsEvent(eventNames.removeFavorite, {
    documentId,
    documentType,
    resourceEffectiveDate,
    resourceExternalVersion,
    resourceId,
    resourceIdentityId,
    resourceIdentityMasterName,
    resourceIdentityAuthorName,
    resourceIdentityResourceType,
    resourceIdentityLanguage,
  });
}

/**
 * When a user detaches a document viewer.
 * @param {Object} eventData All data involved with the event, e.g. documentId, resourceId
 * @param {Number} eventData.documentId The ID of the the document loaded
 * @param {String} eventData.documentType The file type of the document (e.g. pdf, docx)
 * @param {Date}   eventData.resourceEffectiveDate The effective date of the resource
 * @param {Number} eventData.resourceExternalVersion The version of the resource
 * @param {Number} eventData.resourceId The Resource ID of the document loaded
 * @param {Number} eventData.resourceIdentityId The Resource Identity ID of the document loaded
 * @param {String} eventData.resourceIdentityMasterName The master name of the resource identity
 * @param {String} eventData.resourceIdentityAuthorName The author name of the resource. In our system this is equivalent to 'carrier'
 * @param {String} eventData.resourceIdentityResourceType The type of resource (e.g. 'Forms', 'Brochure')
 * @param {String} eventData.resourceIdentityLanguage The language of the resource identity (e.g. 'en' for English)
 */
export function userDetachesDocumentViewer({
  documentId,
  documentType,
  resourceEffectiveDate,
  resourceExternalVersion,
  resourceId,
  resourceIdentityId,
  resourceIdentityMasterName,
  resourceIdentityAuthorName,
  resourceIdentityResourceType,
  resourceIdentityLanguage,
}) {
  return new AnalyticsEvent(eventNames.documentDetachOpen, {
    documentId,
    documentType,
    resourceEffectiveDate,
    resourceExternalVersion,
    resourceId,
    resourceIdentityId,
    resourceIdentityMasterName,
    resourceIdentityAuthorName,
    resourceIdentityResourceType,
    resourceIdentityLanguage,
  });
}

/**
 * When a user closes a document viewer window.
 * @param {Object} eventData All data involved with the event, e.g. documentId, resourceId
 * @param {Number} eventData.documentId The ID of the the document loaded
 * @param {String} eventData.documentType The file type of the document (e.g. pdf, docx)
 * @param {Date}   eventData.resourceEffectiveDate The effective date of the resource
 * @param {Number} eventData.resourceExternalVersion The version of the resource
 * @param {Number} eventData.resourceId The Resource ID of the document loaded
 * @param {Number} eventData.resourceIdentityId The Resource Identity ID of the document loaded
 * @param {String} eventData.resourceIdentityMasterName The master name of the resource identity
 * @param {String} eventData.resourceIdentityAuthorName The author name of the resource. In our system this is equivalent to 'carrier'
 * @param {String} eventData.resourceIdentityResourceType The type of resource (e.g. 'Forms', 'Brochure')
 * @param {String} eventData.resourceIdentityLanguage The language of the resource identity (e.g. 'en' for English)
 * @param {Number} eventData.documentOpenInSeconds The amount of time, in seconds, that the user spent with the detached window open.
 */
export function userClosesDetachedDocumentViewer({
  documentId,
  documentType,
  resourceEffectiveDate,
  resourceExternalVersion,
  resourceId,
  resourceIdentityId,
  resourceIdentityMasterName,
  resourceIdentityAuthorName,
  resourceIdentityResourceType,
  resourceIdentityLanguage,
  documentOpenInSeconds,
}) {
  return new AnalyticsEvent(eventNames.documentDetachClose, {
    documentId,
    documentType,
    resourceEffectiveDate,
    resourceExternalVersion,
    resourceId,
    resourceIdentityId,
    resourceIdentityMasterName,
    resourceIdentityAuthorName,
    resourceIdentityResourceType,
    resourceIdentityLanguage,
    documentOpenInSeconds,
  });
}

/**
 * When a user performs a search
 * @param {Object} eventData
 * @param {String[]} eventData.linesOfBusiness - LinesOfBusiness used to filter the result set
 * @param {String[]} eventData.tagNames - Names of all the defaultTags currently applied
 * @param {String[]} eventData.defaultLabels - Labels currently used to filter the result set
 *
 * @returns AnalyticsEvent
 */
export function userPerformsSearch({
  linesOfBusiness = [],
  tagNames = [],
  defaultLabels = [],
  activeFilters = [],
  how = null,
  query = null,
  queryComplete = null,
}) {
  return new AnalyticsEvent(eventNames.searchQuery, {
    filters: [...linesOfBusiness, ...tagNames, ...defaultLabels, ...activeFilters],
    how,
    query,
    queryComplete,
  });
}

/**
 * When a user triggers a search by changing filters
 * @param {Object} eventData
 * @param {String[]} eventData.linesOfBusiness - LinesOfBusiness used to filter the result set
 * @param {String[]} eventData.tagNames - Names of all the defaultTags currently applied
 * @param {String[]} eventData.defaultLabels - Labels currently used to filter the result set
 *
 * @returns AnalyticsEvent
 */
export function userTriggersSearchByChangingFilters({linesOfBusiness = [], tagNames = [], defaultLabels = []}) {
  return new AnalyticsEvent(eventNames.searchFilter, {filters: [...linesOfBusiness, ...tagNames, ...defaultLabels]});
}

/**
 * When a user adds a favorite/When a user interacts with snippets in the snippets nav bar in the document viewer
 * @param {Object} eventData Data directly related to the event
 * @param {Number} eventData.documentId The ID of the the document loaded
 * @param {String} eventData.documentType The file type of the document (e.g. pdf, docx)
 * @param {String} eventData.resourceEffectiveDate The effective date of the document loaded
 * @param {String} eventData.resourceExternalVersion The external version (name) of the document loaded
 * @param {Number} eventData.resourceId The Resource ID of the document loaded
 * @param {Number} eventData.resourceIdentityId The Resource Identity ID of the document loaded
 * @param {String} eventData.resourceIdentityMasterName The master name of the Resource Identity
 * @param {String} eventData.resourceIdentityAuthorName The author (equivalent to carrier) name of the Resource Identity
 * @param {String} eventData.resourceIdentityResourceType The resource type of the Resource Identity (e.g. 'Brochure', 'Forms')
 * @param {String} eventData.resourceIdentityLanguage The language used for the Resource Identity (e.g. 'en' for English)
 * @param {String} eventData.action The action being performed within document
 * @returns AnalyticsEvent
 */
export function userNavigatesWithinDocument({
  documentId,
  documentType,
  resourceEffectiveDate,
  resourceExternalVersion,
  resourceId,
  resourceIdentityId,
  resourceIdentityMasterName,
  resourceIdentityAuthorName,
  resourceIdentityResourceType,
  resourceIdentityLanguage,
  action,
}) {
  return new AnalyticsEvent(eventNames.documentNavigate, {
    documentId,
    documentType,
    resourceEffectiveDate,
    resourceExternalVersion,
    resourceId,
    resourceIdentityId,
    resourceIdentityMasterName,
    resourceIdentityAuthorName,
    resourceIdentityResourceType,
    resourceIdentityLanguage,
    action,
  });
}
/**
 * When a user switches between Grid/List view in the search results
 * @param {Object} eventData
 * @param {String} eventData.action Represents the navigation action
 *
 * @returns AnalyticsEvent
 */
export function userSwitchesViews({action}) {
  return new AnalyticsEvent(eventNames.userNavigatesSearchResults, {action});
}
/**
 * When a user switches between pages of a collection of documents or links e.g. search results
 * @param {Object} eventData
 * @param {String} eventData.action Represents the navigation action
 * @param {string} eventData.accordionTitle The name of the accordion (which can also be the group by title)
 * @param {string} eventData.type The subaccordion title, e.g. the type is either Links or Documents
 *
 * @returns AnalyticsEvent
 */
export function userSwitchesPages({action, accordionTitle = null, type = null}) {
  return new AnalyticsEvent(eventNames.userNavigatesSearchResults, {action, accordionTitle, type});
}

/**
 * When a user requests a share link for a document.
 * @param {Object} eventData Data directly related to the event
 * @param {Number} eventData.documentId The ID of the the document loaded
 * @param {String} eventData.documentType The file type of the document (e.g. pdf, docx)
 * @param {Number} eventData.resourceId The Resource ID of the document loaded
 * @param {Date}   eventData.resourceEffectiveDate The effective date of the resource
 * @param {Number} eventData.resourceExternalVersion The version of the resource
 * @param {Number} eventData.resourceIdentityId The Resource Identity ID of the document loaded
 * @param {String} eventData.resourceIdentityMasterName The master name of the Resource Identity
 * @param {String} eventData.resourceIdentityAuthorName The author (equivalent to carrier) name of the Resource Identity
 * @param {String} eventData.resourceIdentityResourceType The resource type of the Resource Identity (e.g. 'Brochure', 'Forms')
 * @param {String} eventData.resourceIdentityLanguage The language used for the Resource Identity (e.g. 'en' for English)
 *
 * @returns AnalyticsEvent
 */
export function userRequestsDocumentShareLink({
  documentId,
  documentType,
  resourceEffectiveDate,
  resourceExternalVersion,
  resourceId,
  resourceIdentityId,
  resourceIdentityMasterName,
  resourceIdentityAuthorName,
  resourceIdentityResourceType,
  resourceIdentityLanguage,
}) {
  return new AnalyticsEvent(eventNames.documentShareLinkRequest, {
    documentId,
    documentType,
    resourceEffectiveDate,
    resourceExternalVersion,
    resourceId,
    resourceIdentityId,
    resourceIdentityMasterName,
    resourceIdentityAuthorName,
    resourceIdentityResourceType,
    resourceIdentityLanguage,
  });
}

/**
 * When any user opens a share link.
 * @param {Object} eventData Data directly related to the event
 * @param {Number} eventData.documentId The ID of the the document loaded
 * @param {String} eventData.documentType The file type of the document (e.g. pdf, docx)
 * @param {Number} eventData.resourceId The Resource ID of the document loaded
 * @param {Date}   eventData.resourceEffectiveDate The effective date of the resource
 * @param {Number} eventData.resourceExternalVersion The version of the resource
 * @param {Number} eventData.resourceIdentityId The Resource Identity ID of the document loaded
 * @param {String} eventData.resourceIdentityMasterName The master name of the Resource Identity
 * @param {String} eventData.resourceIdentityAuthorName The author (equivalent to carrier) name of the Resource Identity
 * @param {String} eventData.resourceIdentityResourceType The resource type of the Resource Identity (e.g. 'Brochure', 'Forms')
 * @param {String} eventData.resourceIdentityLanguage The language used for the Resource Identity (e.g. 'en' for English)
 * @param {String} eventData.documentAccessState The access state of the opened document.
 *
 * @returns AnalyticsEvent
 */
export function userOpensShareLink({
  documentId,
  documentType,
  resourceEffectiveDate,
  resourceExternalVersion,
  resourceId,
  resourceIdentityId,
  resourceIdentityMasterName,
  resourceIdentityAuthorName,
  resourceIdentityResourceType,
  resourceIdentityLanguage,
  documentAccessState,
}) {
  return new AnalyticsEvent(eventNames.userShareLinkOpen, {
    documentId,
    documentType,
    resourceEffectiveDate,
    resourceExternalVersion,
    resourceId,
    resourceIdentityId,
    resourceIdentityMasterName,
    resourceIdentityAuthorName,
    resourceIdentityResourceType,
    resourceIdentityLanguage,
    documentAccessState,
  });
}

/**
 * How long was it until the user could see the loaded document for the first time
 * @param {Object} eventData
 * @param {Number} eventData.documentId The ID of the the document loaded
 * @param {Number} eventData.documentLoadTime The total time taken to load the document
 * @param {String} eventData.documentType The file type of the document (e.g. pdf, docx)
 * @param {Number} eventData.resourceId The Resource ID of the document loaded
 * @param {Number} eventData.resourceIdentityId The Resource Identity ID of the document loaded
 * @param {Number} eventData.viewerLoadTime The load time of the viewer, which should be constant during a user session
 *
 * @returns AnalyticsEvent
 */
export function sageLoadsDocument({
  documentId,
  documentLoadTime,
  documentType,
  resourceEffectiveDate,
  resourceExternalVersion,
  resourceId,
  resourceIdentityId,
  resourceIdentityMasterName,
  resourceIdentityAuthorName,
  resourceIdentityResourceType,
  resourceIdentityLanguage,
  viewerLoadTime,
}) {
  return new AnalyticsEvent(eventNames.sageLoadsDocument, {
    documentId,
    documentLoadTime,
    documentType,
    resourceEffectiveDate,
    resourceExternalVersion,
    resourceId,
    resourceIdentityId,
    resourceIdentityMasterName,
    resourceIdentityAuthorName,
    resourceIdentityResourceType,
    resourceIdentityLanguage,
    viewerLoadTime,
  });
}

/**
 * When an admin user creates an external link.
 * @param {Object} eventData
 * @param {Number} eventData.externalLinkId The ID of the created external link.
 * @param {String} eventData.externalLinkURL The url of the created external link.
 * @returns AnalyticsEvent
 */
export function userCreatesExternalLink({externalLinkId, externalLinkURL}) {
  return new AnalyticsEvent(eventNames.externalLinkCreated, {externalLinkId, externalLinkURL});
}

/**
 * @param {Object} eventData
 * @param {String} eventData.action The action performed (expand/collapse)
 *
 * @returns AnalyticsEvent
 */
export function userExpandsOrCollapsesAllAccordions({action}) {
  return new AnalyticsEvent(eventNames.userNavigatesSearchResults, {action});
}

/**
 * @param {Object} eventData
 * @param {String} eventData.action The action performed (expand/collapse)
 * @param {String} eventData.text The title of the accordion expanded/collapsed
 *
 * @returns AnalyticsEvent
 */
export function userExpandsOrCollapsesAccordion({action, text}) {
  return new AnalyticsEvent(eventNames.userNavigatesSearchResults, {action, text});
}

/**
 * @param {Object} eventData
 * @param {String} eventData.action The action performed ('Search_title')
 * @param {String} eventData.text The text of the query in ('Search titles')
 *
 * @returns AnalyticsEvent
 */
export function userQueriesTitleSearch({action, text}) {
  return new AnalyticsEvent(eventNames.userNavigatesSearchResults, {action, text});
}

/**
 * @param {Object} eventData
 * @param {String} eventData.action The action performed ('sort by relevance')
 *
 * @returns AnalyticsEvent
 */
export function userUsesSortByOption({action}) {
  return new AnalyticsEvent(eventNames.userNavigatesSearchResults, {action});
}

/**
 * @param {Object} eventData
 * @param {String} eventData.action The action performed ('group by region')
 *
 * @returns AnalyticsEvent
 */
export function userUsesGroupByOption({action}) {
  return new AnalyticsEvent(eventNames.userNavigatesSearchResults, {action});
}

/**
 * @param {Object} eventData
 * @param {String} eventData.action The action performed ('sort column name')
 *
 * @returns AnalyticsEvent
 */
export function userUsesColumnSortOption({action}) {
  return new AnalyticsEvent(eventNames.userNavigatesSearchResults, {action});
}

/**
 * @param {Object} eventData
 * @param {String} eventData.from The language before switching
 * @param {String} eventData.to The language after switching
 * @returns AnalyticsEvent
 */
export function userSwitchesLanguage({from, to}) {
  return new AnalyticsEvent(eventNames.userSwitchesLanguage, {from, to});
}

export function userDownloadsFile({documentId, resourceId, resourceIdentityId}) {
  return new AnalyticsEvent(eventNames.userDownloadsFile, {documentId, resourceId, resourceIdentityId});
}

export function userPrintsFile({documentId, resourceId, resourceIdentityId}) {
  return new AnalyticsEvent(eventNames.userPrintsFile, {documentId, resourceId, resourceIdentityId});
}

export function userLoginFailure({
  primaryUserId,
  authenticationMethod,
  failureReason,
  redirectPage = null,
  redirectParams = null,
}) {
  return new AnalyticsEvent(eventNames.login, {
    primaryUserId,
    authenticationMethod,
    failureReason,
    type: 'failure',
    redirectPage,
    redirectParams,
    client: process.env.REACT_APP_NAME,
    clientVersion: process.env.REACT_APP_VERSION,
  });
}

export function userLoginSuccess({primaryUserId, authenticationMethod, redirectPage = null, redirectParams = null}) {
  return new AnalyticsEvent(eventNames.login, {
    primaryUserId,
    authenticationMethod,
    redirectPage,
    redirectParams,
    type: 'success',
    client: process.env.REACT_APP_NAME,
    clientVersion: process.env.REACT_APP_VERSION,
  });
}

export function userLoginAttempt({
  primaryUserId,
  authenticationMethod,
  redirectPage = null,
  redirectParams = null,
  scheme = null,
}) {
  return new AnalyticsEvent(eventNames.login, {
    primaryUserId,
    authenticationMethod,
    redirectPage,
    redirectParams,
    scheme,
    type: 'attempt',
    client: process.env.REACT_APP_NAME,
    clientVersion: process.env.REACT_APP_VERSION,
  });
}

export function userDownloadsFileContentUsage({
  documentId,
  documentType,
  resourceEffectiveDate,
  resourceExternalVersion,
  resourceId,
  resourceIdentityId,
  resourceIdentityMasterName,
  resourceIdentityAuthorName,
  resourceIdentityResourceType,
  resourceIdentityLanguage,
}) {
  const eventData = {
    action: CONTENT_USE_ACTIONS.Download,
    contentType: CONTENT_USE_TYPES.Document,
    documentId,
    documentType,
    resourceEffectiveDate,
    resourceExternalVersion,
    resourceId,
    resourceIdentityId,
    resourceIdentityMasterName,
    resourceIdentityAuthorName,
    resourceIdentityResourceType,
    resourceIdentityLanguage,
  };
  return new AnalyticsEvent(eventNames.useContent, eventData);
}

export function userPrintsDocumentContentUsage({
  documentId,
  documentType,
  resourceEffectiveDate,
  resourceExternalVersion,
  resourceId,
  resourceIdentityId,
  resourceIdentityMasterName,
  resourceIdentityAuthorName,
  resourceIdentityResourceType,
  resourceIdentityLanguage,
  action,
  contentType,
}) {
  return new AnalyticsEvent(eventNames.useContent, {
    documentId,
    documentType,
    resourceEffectiveDate,
    resourceExternalVersion,
    resourceId,
    resourceIdentityId,
    resourceIdentityMasterName,
    resourceIdentityAuthorName,
    resourceIdentityResourceType,
    resourceIdentityLanguage,
    action,
    contentType,
  });
}

export function userPrintDialogOpen({documentId, resourceId, resourceIdentityId}) {
  return new AnalyticsEvent(eventNames.userPrintDialogOpen, {documentId, resourceId, resourceIdentityId});
}

export function userOpensProfileMenu() {
  return new AnalyticsEvent(eventNames.userOpensProfileMenu, {});
}

/**
 * When an announcement is sent with the email option selected
 * @param {Object} eventData
 * @param {String} eventData.announcementTitle Title of the announcement
 * @param {String} eventData.announcementId Id of the announcement
 * @param {Boolean} eventData.emailOptionSelected True/false based on if the email option was selected
 *
 * @returns AnalyticsEvent
 */
export function announcementPublished({announcementTitle, announcementId, emailOptionSelected}) {
  return new AnalyticsEvent(eventNames.announcementPublished, {announcementTitle, announcementId, emailOptionSelected});
}

/**
 * When a user clicks on the filters button in the search bar.
 * @param {Object} eventData
 * @param {String} eventData.trigger "On" if the filters are being opened or "Off" if the filters are being closed
 *
 * @returns AnalyticsEvent
 */
export function userTogglesPreSearchFilters({trigger}) {
  return new AnalyticsEvent(eventNames.userTogglesPreSearchFilters, {trigger});
}

/**
 * When a user clicks a link into sage.
 * @param {Object} eventData Event data populated by the 'track' parameter passed by the link.
 *
 * AnalyticsContext data was omitted here as it does not make sense in this case.
 * @returns AnalyticsEvent
 */
export function userClicksLink(eventData) {
  return new AnalyticsEvent(eventNames.userClicksLink, eventData);
}

/** When a user clicks a share button to copy the link.
 * @param {Object} eventData
 * @param {String} eventData.contentType The type of content being shared (e.g. document, external_link, etc)
 * @param {String} eventData.resourceIdentityMasterName The master name of the Resource Identity
 * @param {String} eventData.resourceIdentityAuthorName The author (equivalent to carrier) name of the Resource Identity
 * @param {String} eventData.resourceIdentityResourceType The resource type of the Resource Identity (e.g. 'Brochure', 'Forms')
 * @param {String} eventData.resourceIdentityLanguage The language used for the Resource Identity (e.g. 'en' for English)
 * @param {Date}   eventData.resourceEffectiveDate The effective date of the resource
 * @param {Number} eventData.resourceExternalVersion The version of the resource
 * @param {String} eventData.documentType The file type of the document (e.g. pdf, docx)
 *
 * @returns AnalyticsEvent
 */
export function userCopiesShareLink({
  contentType,
  resourceIdentityMasterName,
  resourceIdentityAuthorName,
  resourceIdentityResourceType,
  resourceIdentityLanguage,
  resourceEffectiveDate,
  resourceExternalVersion,
  documentType,
}) {
  return new AnalyticsEvent(eventNames.useContent, {
    action: CONTENT_USE_ACTIONS.Share,
    contentType,
    resourceIdentityMasterName,
    resourceIdentityAuthorName,
    resourceIdentityResourceType,
    resourceIdentityLanguage,
    resourceEffectiveDate,
    resourceExternalVersion,
    documentType,
  });
}

/**
 * When a user interacts with the document viewer in a way not related to navigation.
 * @param {Object} eventData
 * @param {Object} eventData.action The action being performed (e.g. snippet_intro_clicked, highlight_menu_shown, text_copied)
 * @param {String} eventData.resourceIdentityMasterName The master name of the Resource Identity
 * @param {String} eventData.resourceIdentityAuthorName The author (equivalent to carrier) name of the Resource Identity
 * @param {String} eventData.resourceIdentityResourceType The resource type of the Resource Identity (e.g. 'Brochure', 'Forms')
 * @param {String} eventData.resourceIdentityLanguage The language used for the Resource Identity (e.g. 'en' for English)
 * @param {Date}   eventData.resourceEffectiveDate The effective date of the resource
 * @param {Number} eventData.resourceExternalVersion The version of the resource
 * @param {String} eventData.documentType The file type of the document (e.g. pdf, docx)
 *
 * @returns AnalyticsEvent
 */
export function userInteractsWithDocument({
  action,
  resourceIdentityMasterName,
  resourceIdentityAuthorName,
  resourceIdentityResourceType,
  resourceIdentityLanguage,
  resourceEffectiveDate,
  resourceExternalVersion,
  documentType,
}) {
  return new AnalyticsEvent(eventNames.documentActions, {
    action,
    resourceIdentityMasterName,
    resourceIdentityAuthorName,
    resourceIdentityResourceType,
    resourceIdentityLanguage,
    resourceEffectiveDate,
    resourceExternalVersion,
    documentType,
  });
}

/**
 * When a user interacts with snippets in the document viewer or on the Snippets page.
 * @param {Object} eventData
 * @param {String} eventData.snippetName The name of the snippet
 * @param {String} eventData.snippetTag The tag of the snippet
 * @param {String} eventData.type The type of the snippet (e.g. user, public)
 * @param {String} eventData.action The action being performed (e.g, create, edit, update, delete)
 * @param {String} eventData.resourceIdentityMasterName The master name of the Resource Identity
 * @param {String} eventData.resourceIdentityAuthorName The author (equivalent to carrier) name of the Resource Identity
 * @param {String} eventData.resourceIdentityResourceType The resource type of the Resource Identity (e.g. 'Brochure', 'Forms')
 * @param {String} eventData.resourceIdentityLanguage The language used for the Resource Identity (e.g. 'en' for English)
 * @param {Date}   eventData.resourceEffectiveDate The effective date of the resource
 * @param {Number} eventData.resourceExternalVersion The version of the resource
 * @param {String} eventData.documentType The file type of the document (e.g. pdf, docx)
 *
 * @returns AnalyticsEvent
 */
export function userInteractsWithSnippets({
  snippetName,
  snippetTag = null,
  type,
  action,
  resourceIdentityMasterName,
  resourceIdentityAuthorName,
  resourceIdentityResourceType,
  resourceIdentityLanguage,
  resourceEffectiveDate,
  resourceExternalVersion,
  documentType,
}) {
  return new AnalyticsEvent(eventNames.snippetActions, {
    snippetName,
    snippetTag,
    type,
    action,
    resourceIdentityMasterName,
    resourceIdentityAuthorName,
    resourceIdentityResourceType,
    resourceIdentityLanguage,
    resourceEffectiveDate,
    resourceExternalVersion,
    documentType,
  });
}

/**
 * When a user interacts with snippets custom tags in the document viewer.
 * @param {Object} eventData
 * @param {String} eventData.tagName The name of the custom tag
 * @param {String} eventData.tagId The id of the custom tag
 * @param {String} eventData.action The action being performed (e.g, create, edit, delete)
 * @param {String} eventData.resourceIdentityMasterName The master name of the Resource Identity
 * @param {String} eventData.resourceIdentityAuthorName The author (equivalent to carrier) name of the Resource Identity
 * @param {String} eventData.resourceIdentityResourceType The resource type of the Resource Identity (e.g. 'Brochure', 'Forms')
 * @param {String} eventData.resourceIdentityLanguage The language used for the Resource Identity (e.g. 'en' for English)
 * @param {Date}   eventData.resourceEffectiveDate The effective date of the resource
 * @param {Number} eventData.resourceExternalVersion The version of the resource
 * @param {String} eventData.documentType The file type of the document (e.g. pdf, docx)
 *
 * @returns AnalyticsEvent
 */
export function userInteractsWithSnippetCustomTags({
  tagName = null,
  tagId = null,
  action,
  resourceIdentityMasterName,
  resourceIdentityAuthorName,
  resourceIdentityResourceType,
  resourceIdentityLanguage,
  resourceEffectiveDate,
  resourceExternalVersion,
  documentType,
}) {
  return new AnalyticsEvent(eventNames.snippetActions, {
    tagName,
    tagId,
    action,
    resourceIdentityMasterName,
    resourceIdentityAuthorName,
    resourceIdentityResourceType,
    resourceIdentityLanguage,
    resourceEffectiveDate,
    resourceExternalVersion,
    documentType,
  });
}

/**
 * When a user opens some content.
 * @param {Object} eventData
 * @param {String} eventData.type The type of content being opened (e.g. document, external_link, etc)
 * @param {String} eventData.resourceIdentityMasterName The master name of the Resource Identity
 * @param {String} eventData.resourceIdentityAuthorName The author (equivalent to carrier) name of the Resource Identity
 * @param {String} eventData.resourceIdentityResourceType The resource type of the Resource Identity (e.g. 'Brochure', 'Forms')
 * @param {String} eventData.resourceIdentityLanguage The language used for the Resource Identity (e.g. 'en' for English)
 * @param {Date}   eventData.resourceEffectiveDate The effective date of the resource
 * @param {Number} eventData.resourceExternalVersion The version of the resource
 * @param {String} eventData.documentType The file type of the document (e.g. pdf, docx)
 *
 * @returns AnalyticsEvent
 */
export function userOpensContent({
  type,
  resourceIdentityMasterName,
  resourceIdentityAuthorName,
  resourceIdentityResourceType,
  resourceIdentityLanguage,
  resourceEffectiveDate,
  resourceExternalVersion,
  documentType,
}) {
  return new AnalyticsEvent(eventNames.useContent, {
    action: 'open',
    type,
    resourceIdentityMasterName,
    resourceIdentityAuthorName,
    resourceIdentityResourceType,
    resourceIdentityLanguage,
    resourceEffectiveDate,
    resourceExternalVersion,
    documentType,
  });
}

/**
 * When a user interacts with snippets in the document viewer or on the Snippets page.
 * @param {Object} eventData
 * @param {String} eventData.type The type of action being taken (quickLinks, uploadContent, downloadReport, teamSnippet)
 * @param {String} eventData.action The action being performed (menu, download, upload)
 * @returns AnalyticsEvent
 */
export function adminActions({type, action}) {
  return new AnalyticsEvent(eventNames.adminActions, {
    type,
    action,
  });
}

/**
 * When a user interacts with snippets in the document viewer or on the Snippets page.
 * @param {Object} eventData
 * @param {String} eventData.action The action being performed (tags, sortByNew, sortByOld, changePage)
 * @param {String} eventData.tabName Where the navigation is taking place (MySnippets, TeamSnippets)
 * @param {String} eventData.text Tag name when applicable
 * @param {Number} eventData.newPage New page number when applicable
 * @param {Number} eventData.newPageSize New page size when applicable
 *
 * @returns AnalyticsEvent
 */
export function userNavigatesSnippets({action, tabName, text = '', newPage = null, newPageSize = null}) {
  return new AnalyticsEvent(eventNames.snippetNavigation, {
    action,
    tabName,
    text,
    newPage,
    newPageSize,
  });
}

/**
 * When a user opens some collection sheet.
 * @param {Object} eventData
 * @param {String} eventData.collectionSheetTitle The sheet being opened
 * @param {String} eventData.components Where the user is prompting opening the document from
 * @param {String} eventData.source is opening the sheet coming from within the app or via a link
 *
 * @returns AnalyticsEvent
 */
export function userOpensCollectionSheet({collectionSheetTitle, components, source}) {
  return new AnalyticsEvent(eventNames.useContent, {
    action: 'open',
    components: components,
    contentType: 'collectionSheet',
    collectionSheetTitle: collectionSheetTitle,
    source: source,
  });
}

/**
 * When a user copies the link to some collection sheet.
 * @param {Object} eventData
 * @param {String} eventData.collectionSheetTitle The sheet being shared
 * @param {String} eventData.components Where the event is being fired from
 *
 * @returns AnalyticsEvent
 */
export function userSharesCollectionSheet({collectionSheetTitle, components}) {
  return new AnalyticsEvent(eventNames.useContent, {
    action: 'share',
    components: components,
    contentType: 'collectionSheet',
    collectionSheetTitle: collectionSheetTitle,
  });
}

/**
 * When a user creates a collection sheet.
 * @param {Object} eventData
 * @param {String} eventData.collectionSheetTitle The sheet being created
 * @param {String} eventData.components Where it is being made
 *
 * @returns AnalyticsEvent
 */
export function userCreateCollectionSheet({collectionSheetTitle, components}) {
  return new AnalyticsEvent(eventNames.collectionSheetActions, {
    action: 'create',
    components: components,
    collectionSheetTitle: collectionSheetTitle,
  });
}

/**
 * When a user edits a collection sheet.
 * @param {Object} eventData
 * @param {String} eventData.collectionSheetTitle The sheet being edited
 * @param {String} eventData.components Where it is being edited from
 *
 * @returns AnalyticsEvent
 */
export function userEditsCollectionSheet({collectionSheetTitle, components}) {
  return new AnalyticsEvent(eventNames.collectionSheetActions, {
    action: 'edit',
    components: components,
    collectionSheetTitle: collectionSheetTitle,
  });
}

/**
 * When a user deletes a collection sheet.
 * @param {Object} eventData
 * @param {String} eventData.collectionSheetTitle The sheet being deleted
 * @param {String} eventData.components Where it is being deleted from
 *
 * @returns AnalyticsEvent
 */
export function userDeletesCollectionSheet({collectionSheetTitle, components}) {
  return new AnalyticsEvent(eventNames.collectionSheetActions, {
    action: 'delete',
    components: components,
    collectionSheetTitle: collectionSheetTitle,
  });
}

/**
 * When a user searches collection sheets.
 *
 * @returns AnalyticsEvent
 * @param {Object} eventData
 * @param {String} eventData.components Where the event is being fired from
 */
export function userSearchesCollectionSheets({components}) {
  return new AnalyticsEvent(eventNames.collectionSheetActions, {
    action: 'search',
    components: components,
  });
}

/**
 * When a user sorts collection sheets.
 * @param {Object} eventData
 * @param {String} eventData.sortBy The sort selected by the user
 * @param {String} eventData.components Where the event is being fired from
 *
 * @returns AnalyticsEvent
 */
export function userSortsCollectionSheets({sortBy, components}) {
  return new AnalyticsEvent(eventNames.collectionSheetActions, {
    action: sortBy === COLLECTION_SHEET_SORT_BY_ENUM.alphabetical.dataKey ? 'Sort_by_title' : 'Sort_by_date',
    components: components,
  });
}

/**
 * When a user creates a collection .
 * @param {Object} eventData
 * @param {String} eventData.collectionSheetTitle The sheet to which content is being added
 * @param {String} eventData.collectionContent The content being added
 * @param {String} eventData.components Where the event is being fired from
 *
 * @returns AnalyticsEvent
 */
export function userCreatesCollection({collectionSheetTitle, collectionContent, components}) {
  return new AnalyticsEvent(eventNames.collectionActions, {
    action: 'create',
    components: components,
    collectionContent: collectionContent,
    collectionSheetTitle: collectionSheetTitle,
  });
}

/**
 * When a user edits a collection.
 * @param {Object} eventData
 * @param {String} eventData.collectionSheetTitle The sheet of which content is being edited
 * @param {String} eventData.collectionContent The new text
 * @param {String} eventData.components Where the event is being fired from
 *
 * @returns AnalyticsEvent
 */
export function userEditsCollection({collectionSheetTitle, collectionContent, components}) {
  return new AnalyticsEvent(eventNames.collectionActions, {
    action: 'edit',
    components: components,
    collectionContent: collectionContent,
    collectionSheetTitle: collectionSheetTitle,
  });
}

/**
 * When a user deletes a collection.
 * @param {Object} eventData
 * @param {String} eventData.collectionSheetTitle The sheet of which content is being removed from
 * @param {String} eventData.collectionContent The old text
 * @param {String} eventData.components Where the event is being fired from
 *
 * @returns AnalyticsEvent
 */
export function userDeletesCollection({collectionSheetTitle, collectionContent, components}) {
  return new AnalyticsEvent(eventNames.collectionActions, {
    action: 'delete',
    components: components,
    collectionContent: collectionContent,
    collectionSheetTitle: collectionSheetTitle,
  });
}
