import axios from 'axios';
import {setupCache} from 'axios-cache-adapter';
import * as jwt from 'jsonwebtoken';
import {deserializeAppAnnouncement} from 'lib/app_announcements';
import auth from 'lib/authorization';
import {
  ALERT_LABELS,
  DETACHED_DOCUMENT_VIEWER_STORAGE_PREFIX,
  FAVORITES_FETCH_LIMIT,
  HTTP_RESPONSE_STATUS_CODES,
  RECENTLY_VIEWED_SEARCH_LIMIT,
  RECENTLY_VIEWED_TYPES,
  SEARCH_SESSION_FETCH_LIMIT,
  STORAGE_TO_CLEAR,
} from 'lib/constants';
import {convertToDate} from 'lib/dates';
import {ResponseException} from 'lib/error_helpers';
import {replaceImagesInAnnouncementRawValuesWithAttachmentUrls} from 'lib/rich_text_editor';
import {tokenRefresh} from 'lib/token_refresh';
import {createPassword} from 'lib/utils';
import _ from 'lodash';

let logoutCallback = null;

let localStoredItems = [];

const apiCache = setupCache({
  maxAge: 5 * 60 * 1000, // 5 Minutes
  limit: 100,
  exclude: {
    paths: [
      /^.*\/announcements.*$/g,
      /^.*\/favorites.*$/g,
      /^.*\/notifications.*$/g,
      /^.*\/sessions.*$/g,
      /^.*\/user-snippets.*$/g,
      /^.*\/token.*$/g,
      /^.*\/collection-sheets.*$/g,
      /^.*\/collections.*$/g,
    ],
    query: false, // Allow requests with query parameters to be cached
  },
});

const cachedAxios = axios.create({
  adapter: apiCache.adapter,
});

/** Interface to backed APIs */
class API {
  /**
   * @typedef {Object} Favorites
   * @property {String}   carrier               The display text name of the document's carrier
   * @property {Number}   document_id           The numerical id of the document
   * @property {String}   document_master_name  The master name for the document
   * @property {String}   effective_date        The ISO string of the effective date
   * @property {Number}   favorite_id           The database ID of the favorite
   * @property {String}   labels                A string of the labels associated to the document
   * @property {Number}   latest                Is the document the latest version of a resource
   * @property {String}   line_of_business      A string of all of the documents lines of business, note that it is not the display name
   * @property {Number}   membership_id         The user's membership ID
   * @property {String}   region                A string of the region display names
   * @property {String}   resource_type         A string of the resource type
   */

  /**
   * @typedef {Object} PagedFavorites
   * @property {Favorites[]}    favorites The list of favorites on this page
   * @property {Bool}           hasMore   Used to indicate if there more items available past this page
   * @property {Number}         offset    The offset for the next page if relevant
   */

  setLogoutCallback(callback) {
    logoutCallback = callback;
  }

  callLogoutCallback() {
    this.closeWindows();
    logoutCallback && logoutCallback();
  }

  /** Create a new user for the app and send the user a password reset request */
  async createUser(data, isSSO) {
    const app = await this.getApp();
    await this._fetch(
      process.env.REACT_APP_AUTH_BASE_URL,
      '/user',
      'POST',
      isSSO
        ? {
            user_id: data.email,
            sso: true,
            customer: app.customer_name,
          }
        : {
            user_id: data.email,
            email: data.email,
            password: createPassword(),
            customer: app.customer_name,
          },
    );
    await this._fetch(process.env.REACT_APP_DIALOG_BASE_URL, this.getAppId() + '/membership', 'POST', {
      user_id: data.email,
      email: data.email,
      first_name: data.first_name,
      last_name: data.last_name,
      role: data.role,
      user_settings: {
        default_tags: JSON.stringify([]),
      },
      document_groups: data.document_groups,
      user_groups: data.user_groups,
      active: 1,
    });
    if (!isSSO) {
      return this._fetch(process.env.REACT_APP_AUTH_BASE_URL, '/request-pass', 'POST', {
        user_id: data.email,
        registration: true,
      });
    }
  }

  /** Update user information */
  updateUser(data) {
    return this._fetch(process.env.REACT_APP_DIALOG_BASE_URL, this.getAppId() + '/membership', 'POST', data);
  }

  /** Activate a user */
  activateUser(primaryUserId) {
    return this._fetch(process.env.REACT_APP_DIALOG_BASE_URL, this.getAppId() + '/membership', 'POST', {
      active: 1,
      user_id: primaryUserId,
    });
  }

  /** Deactivate a user */
  deactivateUser(primaryUserId) {
    return this._fetch(process.env.REACT_APP_DIALOG_BASE_URL, this.getAppId() + '/membership', 'POST', {
      active: 0,
      user_id: primaryUserId,
    });
  }

  async getAuthMethods() {
    try {
      const response = await this._fetch(process.env.REACT_APP_AUTH_BASE_URL, '/auth_methods');
      return response.data;
    } catch (errorResponse) {
      throw errorResponse.data;
    }
  }

  /** Determine authentication type (primary_user_id+password or SSO) for the given primary_user_id address */
  async getAuthType(primaryUserId) {
    const queryParams = {user_id: primaryUserId};
    try {
      const response = await this._fetch(
        process.env.REACT_APP_AUTH_BASE_URL,
        '/user/auth_type' + this._buildQueryString(queryParams),
      );
      return response.data;
    } catch (errorResponse) {
      throw errorResponse.data;
    }
  }

  /** Log the user via password or SSO idToken */
  async login({primaryUserId, password, idToken}) {
    const data = {};

    if (primaryUserId) {
      data['user_id'] = primaryUserId;
    }

    if (password) {
      data['password'] = password;
    }

    if (idToken) {
      data['id_token'] = idToken;
    }

    try {
      const tokenResponse = await this._fetch(process.env.REACT_APP_AUTH_BASE_URL, '/token', 'POST', data);
      const token = tokenResponse.data.token;
      const refreshToken = tokenResponse.data.refresh_token;
      await this.updateToken(token, refreshToken);
    } catch (errorResponse) {
      throw errorResponse.data;
    }
  }

  /** Update the token and dependent state */
  async updateToken(token, refreshToken) {
    // this is a re-log potentially to a different account, so we need to log out the existing session
    if (this.isLoggedIn()) {
      try {
        await this.logout();
      } catch (e) {
        console.error(`Error signing out existing session: ${e}`);
      }
    }

    this.setStoredTokens(token, refreshToken);

    const profileResponse = await this._fetch(process.env.REACT_APP_AUTH_BASE_URL, '/profile');
    if (profileResponse) {
      localStorage.setItem('profile', JSON.stringify(profileResponse.data));
    }
  }

  /**  Check if the user is logged by validating the token */
  isLoggedIn() {
    // check to see if there's even a token
    const token = this.getToken();
    const refreshToken = this.getRefreshToken();
    const profile = localStorage.getItem('profile');
    if (!token || !refreshToken || !profile) {
      return false;
    }

    return auth.validate(refreshToken);
  }

  /** Log out the current user */
  async logout() {
    const token = this.getToken();

    this.clearStorage();
    this.clearAPICache();
    this.callLogoutCallback();

    try {
      const response = await this._fetch(process.env.REACT_APP_AUTH_BASE_URL, '/token', 'DELETE', null, token);
      return response.data;
    } catch (errorResponse) {
      throw errorResponse.data;
    }
  }

  /** Clear the local storage variables */
  clearStorage() {
    for (let i = 0; i < STORAGE_TO_CLEAR.length; i++) {
      localStorage.removeItem(STORAGE_TO_CLEAR[i]);
    }
  }

  clearAPICache() {
    apiCache.store.clear();
  }

  closeWindows() {
    localStoredItems.forEach(({itemId, window}) => {
      localStorage.removeItem(itemId);
      window.close();
    });
    localStoredItems = [];

    // Clean up any additional keys which use the prefix
    const additionalKeysToRemove = [];
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.startsWith(DETACHED_DOCUMENT_VIEWER_STORAGE_PREFIX)) {
        additionalKeysToRemove.push(key);
      }
    }
    additionalKeysToRemove.forEach((key) => {
      localStorage.removeItem(key);
    });
  }

  trackDetachedWindow(itemId, window) {
    localStoredItems.push({itemId, window});
  }

  getNumDetachedWindows() {
    return localStoredItems.length;
  }

  /** Set both access and refresh token in one go */
  setStoredTokens(token, refreshToken) {
    localStorage.setItem('token', token);
    localStorage.setItem('refreshToken', refreshToken);
  }

  /** Get the stored user access token */
  getToken() {
    return localStorage.getItem('token');
  }

  /** Get the stored user refresh token */
  getRefreshToken() {
    return localStorage.getItem('refreshToken');
  }

  /** Get the stored user profile */
  getProfile() {
    return JSON.parse(localStorage.getItem('profile'));
  }

  /**
   * Get user's membership from their stored user profile
   * NOTE: this membership data comes from the `auth` service, does not contain full detail,
   *       and may become stale over the duration of a users session.
   */
  getMembership() {
    const currentUrl = new URL(window.location.href);
    const profile = this.getProfile();

    // handle hostnames with www. prepended to the hostname
    const hostname = currentUrl.hostname.startsWith('www.') ? currentUrl.hostname.substring(4) : currentUrl.hostname;

    // sage preference
    const sageHostnames = ['ask-sage.com', 'ask-sage.ca', 'sage.pronavigator.ai', 'sage1.pronavigator.ai'];

    if (sageHostnames.includes(hostname)) {
      for (const membership of profile.memberships) {
        if (membership.app.type === 'sage') {
          return membership;
        }
      }
      return profile.memberships[0];

      // default preference
    } else {
      return profile?.memberships[0];
    }
  }

  /** Get app id from the stored user profile */
  getAppId() {
    return this.getMembership().app.id;
  }

  /** Get the user's email from their token */
  getUserEmail() {
    const token = this.getToken();

    if (token) {
      const decoded = jwt.decode(token);
      return decoded.email;
    }

    return null;
  }

  /** Get the user's primary ID from their token */
  getUserPrimaryID() {
    const token = this.getToken();

    if (token) {
      const decoded = jwt.decode(token);
      return decoded.primary_user_id;
    }

    return null;
  }

  /** Get memberships for all users in this app */
  async getMemberships() {
    const response = await this._fetch(process.env.REACT_APP_DIALOG_BASE_URL, this.getAppId() + '/memberships');
    return response.data;
  }

  /** Get memberships for all users in this app, paged */
  async getPagedMemberships(offset, pageSize) {
    const queryString = this._buildQueryString({
      offset,
      page_size: pageSize,
      client: this.getAppId(),
    });
    const response = await this._fetch(
      process.env.REACT_APP_DIALOG_BASE_URL,
      `${this.getAppId()}/management/memberships${queryString}`,
    );

    const returnData = Object.assign({...response.data}, {hasMore: response.data.has_more});

    return returnData;
  }

  /**
   * Retrieve full-detailed membership data from dialog-api for the current user.
   */
  async getOwnMembership() {
    const resp = await this._fetch(process.env.REACT_APP_DIALOG_BASE_URL, `${this.getAppId()}/membership`);
    return resp.data;
  }

  /** Identify the user to the analytics system */
  identifyUser(client) {
    const token = this.getToken();
    const decoded = jwt.decode(token);
    const membership = this.getMembership();
    const userGroupIds = membership.user_groups.map((group) => group.id);

    const eventBody = {
      // this is some squirrely stuff where there is a analytics ID as well as the users sage ID
      user_id: decoded.jti.toString(),
      user_group_ids: userGroupIds.toString(),
      client: client,
      email: this.getUserEmail(),
      primary_user_id: this.getUserPrimaryID(),
    };

    this._fetch(process.env.REACT_APP_ANALYTICS_BASE_URL, '/identify', 'POST', eventBody);
  }

  /** Reset the user's password */
  async resetPassword(password, confirmPassword, token) {
    try {
      const response = await this._fetch(process.env.REACT_APP_AUTH_BASE_URL, '/reset-pass', 'PUT', {
        confirmPassword: confirmPassword,
        password: password,
        reset_password_token: token,
      });
      return response.data;
    } catch (errorResponse) {
      throw errorResponse.data;
    }
  }

  /** Initialize the password reset flow */
  async requestPassword(primaryUserId) {
    const requestData = {
      user_id: primaryUserId,
    };

    try {
      const response = await this._fetch(process.env.REACT_APP_AUTH_BASE_URL, '/request-pass', 'POST', requestData);
      return response.data;
    } catch (errorResponse) {
      throw errorResponse.data;
    }
  }

  async getNotifications() {
    try {
      const response = await this._fetch(process.env.REACT_APP_DIALOG_BASE_URL, this.getAppId() + '/notifications');

      return response.data;
    } catch (error) {
      throw error.data;
    }
  }

  async getNotificationMetadata(autoRetryAfterAuthFailure = false) {
    try {
      const response = await this._fetch(
        process.env.REACT_APP_DIALOG_BASE_URL,
        this.getAppId() + '/notifications',
        'HEAD',
        null,
        null,
        autoRetryAfterAuthFailure,
      );

      const headers = response.headers;
      const unreadNotificationCount = headers['x-pronav-notifications-unread-count'] || 0;
      const unreadHighPriorityNotificationCount = headers['x-pronav-notifications-unread-high-priority-count'] || 0;

      return {
        unreadNotificationCount,
        unreadHighPriorityNotificationCount,
      };
    } catch (error) {
      throw error.data;
    }
  }

  async markNotificationsRead(timestamp, notificationIds) {
    const data = {
      timestamp: timestamp.toISOString(),
      ids: notificationIds,
    };

    try {
      await this._fetch(process.env.REACT_APP_DIALOG_BASE_URL, this.getAppId() + '/notifications/read', 'POST', data);
    } catch (error) {
      throw error.data;
    }
  }

  async publishAppAnnouncement(
    title,
    isUrgent,
    emailNotification,
    userGroupIds,
    richTextData,
    plainText,
    attachments = [],
    banner,
  ) {
    const data = {
      title,
      is_urgent: isUrgent,
      email_notification: emailNotification,
      user_group_ids: userGroupIds,
      rich_text_data: richTextData,
      plain_text: plainText,
      attachments,
      banner_image_data: banner ? {file_id: banner.fileId, alt_text: banner.altText} : null,
    };

    try {
      const response = await this._fetch(
        process.env.REACT_APP_DIALOG_BASE_URL,
        this.getAppId() + '/announcements',
        'POST',
        data,
      );
      return response.data.id;
    } catch (error) {
      throw error.data;
    }
  }

  async publishAppAnnouncementManagement(
    title,
    isUrgent,
    emailNotification,
    userGroupIds,
    richTextData,
    plainText,
    attachments = [],
    banner,
  ) {
    const data = {
      title,
      is_urgent: isUrgent,
      email_notification: emailNotification,
      user_group_ids: userGroupIds,
      rich_text_data: richTextData,
      plain_text: plainText,
      attachments,
      banner_image_data: banner ? {file_id: banner.fileId, alt_text: banner.altText} : null,
    };

    try {
      const response = await this._fetch(
        process.env.REACT_APP_DIALOG_BASE_URL,
        this.getAppId() + '/announcements/management',
        'POST',
        data,
      );
      return response.data.id;
    } catch (error) {
      throw error.data;
    }
  }

  async listAppAnnouncements(offset, pageSize, dateRange, searchText = null) {
    const data = {
      offset,
      page_size: pageSize,
      date_range: dateRange,
      search_text: searchText,
    };
    try {
      const response = await this._fetch(
        process.env.REACT_APP_DIALOG_BASE_URL,
        this.getAppId() + '/announcements/search',
        'POST',
        data,
      );

      const fetchedData = response.data;

      fetchedData.items = fetchedData.items.map(deserializeAppAnnouncement);

      if (fetchedData?.oldest_publication_date) {
        fetchedData.oldest_publication_date = convertToDate(fetchedData?.oldest_publication_date);
      }

      return fetchedData;
    } catch (error) {
      throw error.data;
    }
  }

  async exportUserList() {
    const response = await this._fetch(
      process.env.REACT_APP_DIALOG_BASE_URL,
      `${this.getAppId()}/management/export-users?client=${this.getAppId()}`,
      'GET',
    );
    return response.data;
  }

  async getManagementAppAnnouncements(offset, pageSize, searchText = null, orderBy = null, orderDirection = null) {
    const requestBody = {
      offset,
      page_size: pageSize,
      search_text: searchText,
      order_by: orderBy,
      order_direction: orderDirection,
    };
    try {
      const response = await this._fetch(
        process.env.REACT_APP_DIALOG_BASE_URL,
        this.getAppId() + '/announcements/management/search',
        'POST',
        requestBody,
      );
      const fetchedData = response.data;

      fetchedData.items = fetchedData.items.map(deserializeAppAnnouncement);

      replaceImagesInAnnouncementRawValuesWithAttachmentUrls(fetchedData.items);

      return fetchedData;
    } catch (error) {
      throw error.data;
    }
  }

  async deleteAppAnnouncement(announcementId) {
    try {
      const response = await this._fetch(
        process.env.REACT_APP_DIALOG_BASE_URL,
        `${this.getAppId()}/announcements/${announcementId}`,
        'DELETE',
      );
      return response.data;
    } catch (error) {
      throw error.data;
    }
  }

  async deleteAppAnnouncementManagement(announcementId) {
    try {
      const response = await this._fetch(
        process.env.REACT_APP_DIALOG_BASE_URL,
        `${this.getAppId()}/announcements/management/${announcementId}`,
        'DELETE',
      );
      return response.data;
    } catch (error) {
      throw error.data;
    }
  }

  async updateAppAnnouncement(
    announcementId,
    title,
    isUrgent,
    emailNotification,
    userGroupIds,
    richTextData,
    plainText,
    attachments = [],
    banner = {},
  ) {
    const data = {
      title,
      is_urgent: isUrgent,
      email_notification: emailNotification,
      user_group_ids: userGroupIds,
      rich_text_data: richTextData,
      plain_text: plainText,
      attachments: attachments,
      banner_image_data: banner ? {file_id: banner.fileId, alt_text: banner.altText} : null,
    };
    try {
      const response = await this._fetch(
        process.env.REACT_APP_DIALOG_BASE_URL,
        `${this.getAppId()}/announcements/${announcementId}`,
        'PUT',
        data,
      );
      return response.data;
    } catch (error) {
      throw error.data;
    }
  }

  async updateAppAnnouncementManagement(
    announcementId,
    title,
    isUrgent,
    emailNotification,
    userGroupIds,
    richTextData,
    plainText,
    attachments = [],
    banner = {},
  ) {
    const data = {
      title,
      is_urgent: isUrgent,
      email_notification: emailNotification,
      user_group_ids: userGroupIds,
      rich_text_data: richTextData,
      plain_text: plainText,
      attachments: attachments,
      banner_image_data: banner ? {file_id: banner.fileId, alt_text: banner.altText} : null,
    };
    try {
      const response = await this._fetch(
        process.env.REACT_APP_DIALOG_BASE_URL,
        `${this.getAppId()}/announcements/management/${announcementId}`,
        'PUT',
        data,
      );
      return response.data;
    } catch (error) {
      throw error.data;
    }
  }

  /***
   * Fetch an announcement by it's identifier
   * @param {int} announcementId - Unique identifier of the announcement
   * @returns {object} - An announcement with attachment data
   */
  async getAnnouncementById(announcementId) {
    const response = await this._fetch(
      process.env.REACT_APP_DIALOG_BASE_URL,
      `${this.getAppId()}/announcements/${announcementId}`,
      'GET',
    );
    return response.data;
  }

  async getResourceByResourceId(resourceId) {
    try {
      const response = await this._fetch(
        process.env.REACT_APP_DIALOG_BASE_URL,
        `${this.getAppId()}/resources/${resourceId}`,
      );

      return response.data;
    } catch (error) {
      throw error.data;
    }
  }

  /** Get a document by id */
  async getDocument(
    id,
    {
      includeAlternateVersionInfo = false,
      includeTagData = false,
      includeFutureVersions = false,
      includeResourceExternalVersion = false,
    } = {},
  ) {
    const queryString = this._buildQueryString({
      include_alternate_version_info: includeAlternateVersionInfo,
      include_tag_data: includeTagData,
      include_future_versions: includeFutureVersions,
      include_resource_external_version: includeResourceExternalVersion,
    });
    try {
      const response = await this._fetch(
        process.env.REACT_APP_DIALOG_BASE_URL,
        `${this.getAppId()}/resources/document/${id}${queryString}`,
      );

      return response.data;
    } catch (error) {
      throw new ResponseException(error);
    }
  }

  /** Get a document by the resource id */
  async getDocumentByResourceId(resourceId, {includeAlternateVersionInfo = false, includeTagData = false} = {}) {
    const queryString = this._buildQueryString({
      include_alternate_version_info: includeAlternateVersionInfo,
      include_tag_data: includeTagData,
    });
    try {
      const response = await this._fetch(
        process.env.REACT_APP_DIALOG_BASE_URL,
        `${this.getAppId()}/resources/${resourceId}/document${queryString}`,
      );

      return response.data;
    } catch (error) {
      throw new ResponseException(error);
    }
  }

  async getAlternateVersionsByResourceId(resourceId, includeFutureVersions = false) {
    const queryString = this._buildQueryString({
      include_future_versions: includeFutureVersions,
    });
    try {
      const response = await this._fetch(
        process.env.REACT_APP_DIALOG_BASE_URL,
        `${this.getAppId()}/resources/${resourceId}/alternate_versions${queryString}`,
      );

      return response.data;
    } catch (error) {
      throw error.data;
    }
  }

  async searchDocumentForPhrase(documentId, text, page, {includeAmbient = false} = {}) {
    try {
      const queryString = this._buildQueryString({
        text,
        page,
        ambient_matching: includeAmbient,
      });

      const response = await this._fetch(
        process.env.REACT_APP_DIALOG_BASE_URL,
        `${this.getAppId()}/resources/search/phrase/${documentId}${queryString}`,
      );

      return response.data;
    } catch (error) {
      throw error.data;
    }
  }

  /** Request an upload url for a new resource in a bulk upload */
  async requestPreSignedBulkUpload(fileName, contentType) {
    try {
      const response = await this._fetch(
        process.env.REACT_APP_DIALOG_BASE_URL,
        this.getAppId() +
          '/resources/bulk_upload_url' +
          '?file_name=' +
          encodeURIComponent(fileName) +
          '&content_type=' +
          encodeURIComponent(contentType),
      );
      return response.data;
    } catch (errorResponse) {
      throw errorResponse.data;
    }
  }

  /** Upload a document */
  async presignedUploadResource(url, presignFields, fileObj) {
    const formData = new FormData();
    Object.keys(presignFields).forEach(function (key, index) {
      formData.append(key, presignFields[key]);
    });
    formData.append('file', fileObj);

    return axios({
      method: 'POST',
      url: url,
      data: formData,
      config: {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
        },
      },
    });
  }

  /** Check if a file exists in our backend */
  async assertFileUploaded(fileName) {
    try {
      const queryParams = {file: fileName};
      const response = await this._fetch(
        process.env.REACT_APP_DIALOG_BASE_URL,
        this.getAppId() + '/resources/get_file_uploaded_status' + this._buildQueryString(queryParams),
        'GET',
      );
      return response.data;
    } catch (errorResponse) {
      throw errorResponse.data;
    }
  }

  /** Report the status of the uploaded documents */
  async submitUploadStatus(statusList) {
    try {
      const response = await this._fetch(
        process.env.REACT_APP_DIALOG_BASE_URL,
        this.getAppId() + '/resources/upload_status',
        'POST',
        statusList,
      );
      return response.data;
    } catch (errorResponse) {
      throw errorResponse.data;
    }
  }

  /** File a support ticket */
  async fileSupportTicket(overview, description, category) {
    try {
      const response = await this._fetch(
        process.env.REACT_APP_DIALOG_BASE_URL,
        this.getAppId() + '/support-ticket',
        'POST',
        {
          overview: overview,
          description: description,
          category: category,
        },
      );
      return response.data;
    } catch (errorResponse) {
      throw errorResponse.data;
    }
  }

  /**
   * Fetch tags from dialog api associated to the users' app
   * @param {boolean} useClient - Whether tag results will be restricted by app, user groups, and existing documents
   * @param {boolean} forUserGroups - Whether tag results are filtered by user groups and not documents.
   * NOTE: if useClient is true, it will take precedence
   * @param {string} lineOfBusiness - if provided, filters results by line of business
   * @param {string} language - if provided, filters results by language code
   * @returns A dictionary of tags for the app
   */
  async getTags({useClient = true, forUserGroups = false, lineOfBusiness = null, language = null} = {}) {
    const queryParams = {
      use_client: useClient ? 'true' : 'false',
      for_user_groups: forUserGroups ? 'true' : 'false',
    };

    if (lineOfBusiness) queryParams['line_of_business'] = lineOfBusiness;
    if (language) queryParams['language'] = language;

    const response = await this._fetch(
      process.env.REACT_APP_DIALOG_BASE_URL,
      this.getAppId() + '/tags' + this._buildQueryString(queryParams),
    );
    return response.data;
  }

  /** Get app information */
  async getApp() {
    const response = await this._fetch(process.env.REACT_APP_DIALOG_BASE_URL, this.getAppId());
    return response.data;
  }

  /** Set the user's language preference.
   *  language: a language code
   */
  async setLanguage(language) {
    const primaryUserId = this.getUserPrimaryID();
    await this._fetch(process.env.REACT_APP_DIALOG_BASE_URL, this.getAppId() + '/membership', 'POST', {
      user_id: primaryUserId,
      user_settings: {
        language: language,
      },
    });
  }

  /** Private Method
   *  Build a query string from a dictionary of parameters
   */
  _buildQueryString(params) {
    const queryString = Object.entries(params)
      .filter(([key, value]) => value)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&');

    if (queryString) {
      return '?' + queryString;
    } else {
      return '';
    }
  }

  /**
   * @private
   * Attempts to normalize the passed in value in a deterministic fashion, such that the
   * serialization of two Objects with the same properties & values, where the properties
   * may not be defined in the same order, will serialize to the same value.
   * @param {*} value
   * @returns {*}
   */
  _attemptDeterministicallyNormalize(value) {
    if (_.isPlainObject(value)) {
      // Return a new Object with the keys in sorted order and values recursively normalized
      return Object.assign(
        ...Object.entries(value)
          .sort(([k1], [k2]) => k1.localeCompare(k2))
          .map(([k, v]) => ({[k]: this._attemptDeterministicallyNormalize(v)})),
      );
    }
    if (Array.isArray(value)) {
      // Return a new Array with values recursively normalized
      return Array.prototype.map.call(value, (v) => this._attemptDeterministicallyNormalize(v));
    }
    return value;
  }

  /**
   * @private
   * Data store of currently in-progress API requests which can be chained off of if another
   * request with the same parameters is made.
   * Key is the serialized request options.
   * Value is the unfulfilled Promise of the already in-progress request.
   * @type {Map<String, Promise>}
   */
  _chainableRequests = new Map();

  /**
   * @private
   * Helper function which will attempt to reuse any already in-progress API requests
   * with the exact same request options, rather than making a duplicate request.
   * If there is no matching request in-progress, or if the request is determined to
   * not be chainable (ex. it is not a GET request), then a new API request will be made.
   * @param {Object} combinedOptions The combined request options
   * @returns {Promise<AxiosResponse>}
   */
  _chainingFetch(combinedOptions) {
    let isChainable = combinedOptions.method && combinedOptions.method.toLowerCase() === 'get';
    let cacheKey = null;

    if (isChainable) {
      // Attempt to deterministically serialize the request options, to allow for matching with
      // an equivalent request which may have specified options in a different order.
      try {
        cacheKey = JSON.stringify(this._attemptDeterministicallyNormalize(combinedOptions));
      } catch (e) {
        isChainable = false;
      }
    }

    if (!isChainable) {
      // Request is not chainable, so just make a new API call
      return cachedAxios(combinedOptions);
    }

    // Check if we have an equivalent request already in progress and re-use the Promise
    const inProgressPromise = this._chainableRequests.get(cacheKey);
    if (inProgressPromise) {
      return inProgressPromise.then((response) => _.cloneDeep(response));
    }

    // Otherwise, make a new API request and add the Promise to our data store of current chainable requests
    const promise = (async () => {
      const response = await cachedAxios(combinedOptions);
      // Remove the Promise from the data store before it is fulfilled
      if (this._chainableRequests.get(cacheKey) === promise) {
        this._chainableRequests.delete(cacheKey);
      }
      return response;
    })();
    this._chainableRequests.set(cacheKey, promise);
    return promise.then((response) => _.cloneDeep(response));
  }

  // supports non auth retries too, defaults to off
  _retryFetch(combinedOptions, externalToken, authRetry = true, attemptsLeft = 0) {
    return this._chainingFetch(combinedOptions)
      .catch(async (error) => {
        if (
          error.response?.status === HTTP_RESPONSE_STATUS_CODES.UNAUTHORIZED &&
          authRetry &&
          !externalToken &&
          'Authorization' in combinedOptions.headers
        ) {
          await tokenRefresh.refresh();

          combinedOptions.headers['Authorization'] = `Bearer ${this.getToken()}`;

          return this._retryFetch(combinedOptions, externalToken, false, attemptsLeft);
        }

        if (attemptsLeft <= 0) {
          throw error;
        }

        return this._retryFetch(combinedOptions, externalToken, authRetry, attemptsLeft - 1);
      })
      .then((response) => {
        return response;
      });
  }

  /** Private Method
   *  Make an HTTP request.
   */
  _rawFetch(token, baseURL, url, method = 'GET', data = null, externalToken = false, authRetry = true) {
    const headers = {};

    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    const combinedOptions = {
      baseURL,
      url,
      method,
      headers,
      data,
      crossDomain: true,
      withCredentials: false,
    };

    return this._retryFetch(combinedOptions, externalToken, authRetry, 0)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.error) {
          switch (error.response.data.error) {
            case 'Signature has expired':
            case 'Invalid segment encoding':
              this.clearStorage();
              this.clearAPICache();
              break;
            default:
              throw error;
          }
        } else {
          throw error;
        }
      });
  }

  _fetch(baseURL, url, method = 'GET', requestBody = null, token3rdParty = null, authRetry = true) {
    const token = token3rdParty || this.getToken();

    return this._rawFetch(token, baseURL, url, method, requestBody, Boolean(token3rdParty), authRetry)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const status = error.response ? error.response.status : -1;
        if (!authRetry && status === 401) {
          this.callLogoutCallback();
        }

        if (!(error.response && error.response.data)) {
          error.response = {
            data: error.message,
            status: error.status,
          };
        }

        // We really shouldn't throw a non-Error type
        // we're only doing it here because the old _fetch did it that way
        throw error.response;
      });
  }

  /** Log analytics */
  logAnalytics(eventId, values = {}, {language = null} = {}, overrideUserId = null) {
    try {
      const token = this.getToken();
      const decoded = jwt.decode(token);
      const primaryUserId = overrideUserId ? overrideUserId : this.getUserPrimaryID();
      // fire-and-forget, plus no need to use the version of _fetch that logs
      this._rawFetch(token, process.env.REACT_APP_ANALYTICS_BASE_URL, '/event', 'POST', [
        {
          user_id: decoded.jti.toString(),
          email: this.getUserEmail(),
          primary_user_id: primaryUserId,
          code: eventId,
          line_of_business: null,
          language_code: language,
          value_str_1: values.str_1,
          value_str_2: values.str_2,
          value_str_3: values.str_3,
          value_str_4: values.str_4,
          value_str_5: values.str_5,
          value_num_1: values.num_1,
          value_num_2: values.num_2,
          value_num_3: values.num_3,
          value_num_4: values.num_4,
          value_num_5: values.num_5,
        },
      ]).catch((error) => {
        this.internalAlert({
          label: ALERT_LABELS.INTERNAL_ANALYTICS_EVENT_FAILED,
          eventId,
          error,
        });
        console.log(`logAnalytics failed: ${error.name}: ${error.message}`);
      });
    } catch (error) {
      this.internalAlert({
        label: ALERT_LABELS.INTERNAL_ANALYTICS_EVENT_FAILED,
        eventId,
        error,
      });
    }
  }

  async internalAlert(alert) {
    try {
      await this._fetch(process.env.REACT_APP_DIALOG_BASE_URL, `${this.getAppId()}/internal_alert`, 'POST', alert);
    } catch (err) {
      console.error('Unable to POST internal alert', alert, err);
    }
  }

  /**
   * Fetches global config data that we only want to expose to authenticated users.
   */
  async getGlobalConfig() {
    const resp = await this._fetch(process.env.REACT_APP_DIALOG_BASE_URL, '/global_config');
    if (resp) {
      return resp.data;
    }
    throw new Error('Unable to fetch global config');
  }

  /**
   * Fetches all available Lines of Business for the current app
   * @param {Object} [options]
   * @param {string} [options.languageCode] - The language the lines of businesses should be returned in
   * @param {boolean} [options.forUserGroups] - Whether to restrict the results of the lines of businesses returned by the users' user groups
   */
  async getAppLinesOfBusiness({languageCode = 'en', forUserGroups = false} = {}) {
    const queryParams = {
      language_code: languageCode,
      for_user_groups: forUserGroups ? 'true' : 'false',
    };

    const resp = await this._fetch(
      process.env.REACT_APP_DIALOG_BASE_URL,
      `${this.getAppId()}/lines-of-business${this._buildQueryString(queryParams)}`,
    );
    return resp.data.data.lines_of_business;
  }

  /**
   * Fetches all possible Regions available for this app, regardless of user-group permissions.
   */
  async getGlobalRegions() {
    const resp = await this._fetch(process.env.REACT_APP_DIALOG_BASE_URL, '/regions');
    return resp.data.data.regions;
  }

  /**
   * Fetches all possible Resource Types available for this app, regardless of user-group permissions.
   */
  async getAppResourceTypes() {
    const resp = await this._fetch(process.env.REACT_APP_DIALOG_BASE_URL, `${this.getAppId()}/resource-types`);
    return resp.data.data.resource_types;
  }

  /**
   * Fetches all possible Resource Authors available for this app, regardless of user-group permissions.
   */
  async getAppResourceAuthors() {
    const resp = await this._fetch(process.env.REACT_APP_DIALOG_BASE_URL, `${this.getAppId()}/authors`);
    return resp.data;
  }

  /**
   * Fetches all possible Resource Authors available for the current user.
   */
  async getUserResourceAuthors() {
    const resp = await this._fetch(process.env.REACT_APP_DIALOG_BASE_URL, `${this.getAppId()}/resources/authors`);
    return resp.data.authors;
  }

  /**
   * Fetches all User Groups for this app.
   */
  async getAppUserGroups() {
    const resp = await this._fetch(process.env.REACT_APP_DIALOG_BASE_URL, `${this.getAppId()}/user-groups`);
    return resp.data.data;
  }

  /**
   * Creates a new User Group
   * @param {Object} data An object describing the configuration of the new group
   */
  async createUserGroup(data) {
    const appId = this.getAppId();
    await this._fetch(process.env.REACT_APP_DIALOG_BASE_URL, `${appId}/user-groups`, 'POST', data);
  }

  /**
   * Updates an existing User Group
   * @param {String} groupId The ID of the group to be updated
   * @param {Object} data    An object representing the new configuration of the group
   */
  async updateUserGroup(groupId, data) {
    await this._fetch(process.env.REACT_APP_DIALOG_BASE_URL, `${this.getAppId()}/user-groups/${groupId}`, 'PUT', data);
  }

  /**
   * Deletes an existing User Group
   * @param {String} groupId the ID of the group to be deleted.
   */
  async deleteUserGroup(groupId) {
    await this._fetch(process.env.REACT_APP_DIALOG_BASE_URL, `${this.getAppId()}/user-groups/${groupId}`, 'DELETE');
  }

  async getAvailableReports(pageNumber, pageSize) {
    const queryParams = {
      offset: pageNumber * pageSize,
      limit: pageSize,
    };

    const response = await this._fetch(
      process.env.REACT_APP_DIALOG_BASE_URL,
      `${this.getAppId()}/reports${this._buildQueryString(queryParams)}`,
    );

    return {
      data: response.data.items,
      page: pageNumber,
      totalCount: response.data.total,
    };
  }

  async getReportDownloadLink(reportName) {
    const resp = await this._fetch(process.env.REACT_APP_DIALOG_BASE_URL, `${this.getAppId()}/reports/` + reportName);
    return resp.data.link;
  }

  /**
   * Fetches the labels available to this app.
   * @param {boolean} forUserGroups - Whether to restrict the results of the labels returned by the users' user groups
   */
  async getAllLabels(forUserGroups = false) {
    const queryParams = {
      for_user_groups: forUserGroups ? 'true' : 'false',
    };

    const resp = await this._fetch(
      process.env.REACT_APP_DIALOG_BASE_URL,
      `${this.getAppId()}/labels${this._buildQueryString(queryParams)}`,
    );

    return resp.data;
  }

  /**
   * Fetches the labels available to this user.
   * @returns
   */
  async getAppLabels(language) {
    const resp = await this._fetch(
      process.env.REACT_APP_DIALOG_BASE_URL,
      `${this.getAppId()}/labels` + (language ? `?language=${language}` : ''),
    );
    return resp.data;
  }

  async getAvailableTags(lineOfBusiness, language, useClient) {
    const response = await this.getTags({useClient, forUserGroups: false, lineOfBusiness, language});

    // Filter out empty tag categories and sort tag values by title
    const availableTags = Object.entries(response)
      .filter(([, values]) => values.length > 0)
      .reduce((tags, [category, values]) => {
        tags[category] = values.sort((valueA, valueB) => {
          const titleA = valueA.title.toUpperCase();
          const titleB = valueB.title.toUpperCase();
          if (titleA < titleB) {
            return -1;
          } else if (titleA > titleB) {
            return 1;
          } else {
            return 0;
          }
        });
        return tags;
      }, {});

    return availableTags;
  }

  async getAppRegions() {
    const response = await this._fetch(process.env.REACT_APP_DIALOG_BASE_URL, `${this.getAppId()}/regions`);
    return response.data.data.regions;
  }

  /**
   * Get a list of the favorites, since the endpoint is paged this might be a subset of the users favorites
   * @param   {Number}             offset     The number of jobs to skip before starting to collect the result set
   * @param   {Number}             pageSize   The maximum number of jobs to return
   * @param   {String}             sortBy     The order to sort by, if any
   * @returns {PagedFavorites}                The items fetched along with information on fetching the next page if
   * relevant
   */
  async getFavorites(offset = 0, pageSize = FAVORITES_FETCH_LIMIT, sortBy = null) {
    const queryParams = {
      offset: offset,
      page_size: pageSize,
      sort_by: sortBy,
    };

    const response = await this._fetch(
      process.env.REACT_APP_DIALOG_BASE_URL,
      `${this.getAppId()}/favorites${this._buildQueryString(queryParams)}`,
    );

    const responseData = response.data;
    const newOffset = responseData.favorites.length + offset;

    return {
      favorites: responseData.favorites,
      hasMore: newOffset < responseData.total,
      offset: newOffset,
    };
  }

  async getFavorite(favoriteId) {
    const response = await this._fetch(
      process.env.REACT_APP_DIALOG_BASE_URL,
      `${this.getAppId()}/favorites/${favoriteId}`,
      'GET',
      null,
      null,
      true,
    );
    return response.data;
  }

  async addFavorite(documentId, sectionId) {
    const response = await this._fetch(process.env.REACT_APP_DIALOG_BASE_URL, `${this.getAppId()}/favorites`, 'POST', {
      documentId,
      sectionId,
    });
    return response.data;
  }

  async deleteFavorite(favoriteId) {
    const response = await this._fetch(
      process.env.REACT_APP_DIALOG_BASE_URL,
      `${this.getAppId()}/favorites/${favoriteId}`,
      'DELETE',
    );
    return response.data;
  }

  async getDocumentShareLink(resourceIdentityId) {
    const response = await this._fetch(process.env.REACT_APP_SAGE_SHARE_BASE_URL, '/document-link', 'POST', {
      resource_identity_id: resourceIdentityId,
      app_key: this.getAppId(),
    });
    return response.data;
  }

  async getDocumentByResourceIdentityId(resourceIdentityId, state = null) {
    const queryParams = {resource_identity_id: resourceIdentityId};

    if (state) {
      queryParams['state'] = state;
    }

    try {
      const response = await this._fetch(
        process.env.REACT_APP_DIALOG_BASE_URL,
        this.getAppId() + '/resources/document' + this._buildQueryString(queryParams),
        'GET',
      );

      const stringToTime = (date) => new Date(date).getTime();
      const mostRecent = response.data.reduce(
        (mostRecent, currentDoc) =>
          stringToTime(currentDoc.Version.effective_date) > stringToTime(mostRecent.Version.effective_date)
            ? currentDoc
            : mostRecent,
        response.data[0],
      );

      return mostRecent;
    } catch (error) {
      throw new ResponseException(error);
    }
  }

  async logContentView(contentType, contentId, viewedAt) {
    const response = await this._fetch(
      process.env.REACT_APP_DIALOG_BASE_URL,
      `${this.getAppId()}/content-views`,
      'POST',
      {
        content_type: contentType,
        content_id: contentId,
        viewed_at: viewedAt,
      },
    );
    return response.data;
  }

  async logDocumentView(identityId, viewedAt) {
    return await this.logContentView(RECENTLY_VIEWED_TYPES.DOCUMENT, identityId, viewedAt);
  }

  async logExternalLinkView(externalLinkId, viewedAt) {
    return await this.logContentView(RECENTLY_VIEWED_TYPES.EXTERNAL_LINK, externalLinkId, viewedAt);
  }

  /**
   * API call to get the most recent searches associated to the calling user.
   * @param {string} language - Language code (one of ["en", "fr"])
   * @param {number} limit - The maximum number of results to return
   * @param {boolean} includeBlankQueries - Whether to include blank searches in results
   * @returns An array of queries to be display in recent searches
   */
  async getUserRecentSearches(language, limit = SEARCH_SESSION_FETCH_LIMIT, includeBlankQueries = false) {
    try {
      const membership = this.getMembership();
      if (!membership) {
        return [];
      }

      // booleans must be passed as a string because they will be filtered out otherwise
      // eslint-disable-next-line max-len
      const queryParams = {
        limit,
        language_code: language,
        include_blank_queries: includeBlankQueries ? 'true' : 'false',
      };

      const response = await this._fetch(
        process.env.REACT_APP_SEARCH_BASE_URL,
        `${this.getAppId()}/sessions${this._buildQueryString(queryParams)}`,
        'GET',
      );
      return response.data.results;
    } catch (error) {
      throw new ResponseException(error);
    }
  }
  /**
   * API call to get the most recently viewed items associated to the calling user.
   * @param {*} language - language code (one of ['en', 'fr']);
   * @param {*} limit - the maximum number of results to return
   * @returns Array of recently viewed items associated with the calling user
   */
  async getUserRecentViews(offset = 0, limit = RECENTLY_VIEWED_SEARCH_LIMIT, language = 'en') {
    try {
      const membership = this.getMembership();
      if (!membership) {
        return [];
      }
      const queryParams = {
        offset: offset,
        limit: limit,
        language_code: language,
      };
      const response = await this._fetch(
        process.env.REACT_APP_DIALOG_BASE_URL,
        `${this.getAppId()}/content-views${this._buildQueryString(queryParams)}`,
        'GET',
      );
      return response.data;
    } catch (error) {
      throw new ResponseException(error);
    }
  }
  async getExternalLinkById(id) {
    try {
      const response = await this._fetch(
        process.env.REACT_APP_SAGE_CONTENT_BASE_URL,
        `${this.getAppId()}/external-links/${id}`,
        'GET',
      );
      const mostRecent = response.data;

      return mostRecent;
    } catch (error) {
      throw new ResponseException(error);
    }
  }

  async getNewAndUpdatedDocs(limit = 10) {
    const response = await this._fetch(
      process.env.REACT_APP_DIALOG_BASE_URL,
      `${this.getAppId()}/new-and-updated?limit=${limit}`,
    );

    const responseData = response.data;

    return responseData;
  }
}

export default new API();
